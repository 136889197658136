.slider-home {
  max-width: 100vw;
}

#s4-bodyContainer {
  .background-image {
    background-color: white;
    background-image: url(../../assets/img/record/bg-decal.png);
    background-repeat: no-repeat;
    background-size: 220%;

    @media (min-width: 768px) {
      padding-left: 40px;
      padding-right: 40px;
      background-size: 100%;
      background-position: center 40px;
    }

    @media (min-width: 1280px) {
      background-position: center -20px;
    }

    @media (min-width: 2000px) {
      background-position: center -200px;
    }

    @media (min-width: 2560px) {
      background-position: center -200px;
    }

    @media (min-width: 3840px) {
      background-position: center -500px;
    }

    @media (min-width: 5120px) {
      background-position: center -700px;
    }
  }

  .welcome-section {
    padding-left: 40px;
    padding-right: 40px;

    @media (min-width: 1280px) {
      padding-top: 80px;
    }

    .container {
      @apply px-0;
    }

    &__image {
      width: 100%;

      @media (min-width: 1200px) {
        width: 80%;
        margin: 0 auto;
      }
    }

    &__image-box {
      padding: 16px 32px;

      @media (min-width: 1200px) {
        padding: 32px;
      }
    }

    &__text-block {
      h2 {
        @media (min-width: 1280px) {
          font-size: 48px;
          line-height: 55px;
        }
      }

      p {
        @media (min-width: 1280px) {
          font-size: 26px;
          line-height: 38px;
        }
      }
    }
  }

  .thumbnail-slider {
    width: 100%;
    justify-content: center;
    margin-top: 24px;

    @media (min-width: 768px) {
      max-width: 240px;
      margin-left: 50%;
      margin-top: 0;
      justify-content: center;
    }

    #thumbnail-slider-list {
      justify-content: center;
    }

    .splide__arrow {
      background-color: #00aef0;
      height: 48px;
      width: 48px;

      &:hover,
      &:active,
      &:focus,
      &[disabled] {
        background-color: #00aef0;
      }

      @media (min-width: 768px) {
        height: 35px;
        width: 35px;
      }

      @media (min-width: 1200px) {
        height: 48px;
        width: 48px;
      }

      .feather {
        color: #fff;
        font-size: 33px;

        @media (min-width: 768px) {
          font-size: 18px;
        }

        @media (min-width: 1200px) {
          font-size: 33px;
        }
      }
    }

    &__pagination {
      width: 14px;
      height: 14px;
      background-color: #00aef0;
      border-radius: 50%;
      display: inline-block;
      margin: 0 auto;
      opacity: 0.47;

      @media (min-width: 768px) {
        width: 8px;
        height: 8px;
      }

      @media (min-width: 1200px) {
        width: 14px;
        height: 14px;
      }
    }

    /* .splide__track {
      width: 60%;
      margin: 0 auto;
  } */

    .splide__slide {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      &.is-active {
        border: none !important;

        .thumbnail-slider__pagination {
          opacity: 1;
        }
      }

      &.is-visible {
        border: none !important;
      }
    }

    /* .splide__list {
      justify-content: center;

      @media (min-width: 1200px) {
          width: 80%;
          margin: 0 auto !important;
      }

  } */
  }
}

.banner-home-title {
  margin: 6px 0;
  font-size: 21px;
  line-height: 1.1 !important;
  font-weight: bold;
  font-family: FreightSansProBold;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 30px;
  }

  @media (min-width: 1280px) {
    font-size: 55px;
    line-height: 58px;
  }
}

h2.banner-home__title {
  margin: 6px 0;
  color: #264e72 !important;
  font-size: 21px;
  line-height: 25px;
  font-family: 'FreightSansProBold';
  font-weight: 700;
  text-align: center;
}

@media (min-width: 768px) {
  h2.banner-home__title {
    font-size: 28px;
    line-height: 30px;
  }
}

@media (min-width: 1280px) {
  .banner-position.Centralizado h2.banner-home__title,
  .banner-position.Derecha h2.banner-home__title,
  .banner-position.Izquierda h2.banner-home__title {
    font-size: 55px !important;
    margin: 6px 0;
    line-height: 58px !important;
    font-family: 'FreightSansProBold';
    font-weight: 700;
  }
}

@media (min-width: 768px) {
  .banner-position.Centralizado {
    width: 50%;
    margin: 0 auto;
  }

  .banner-position.Centralizado h2.banner-home__title,
  .banner-position.Centralizado .slider-home__text,
  .banner-position.Centralizado .slider-home__anchor-block {
    text-align: center !important;
  }

  .banner-position.Derecha {
    width: 50%;
    float: right;
  }

  .banner-position.Derecha h2.banner-home__title,
  .banner-position.Derecha .slider-home__text,
  .banner-position.Derecha .slider-home__anchor-block {
    text-align: right !important;
  }

  .banner-position.Izquierda {
    width: 50%;
  }

  .banner-position.Izquierda h2.banner-home__title,
  .banner-position.Izquierda .slider-home__text,
  .banner-position.Izquierda .slider-home__anchor-block {
    text-align: left !important;
  }
}

.contacto__text__email {
  width: 60vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

@media (min-width: 768px) {
  .contacto__text__email {
    width: 30vw;
  }
}
@media (min-width: 1024px) {
  .contacto__text__email {
    width: 17vw;
  }
}
