.toggle-list {
  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid #C6C6C6;
    }

    input[type="checkbox"] {
      @apply hidden;

      &:checked {

        /* TODO: Remover en SharePoint, manejar este estado a trav�s de la clase 'minus', se tiene aqu� �nicamente para fines de maquetaci�n */
        +label {
          .arrow-icon {
            .icon-container {
              transform: rotate(90deg);
            }
          }
        }

        ~.content {
          @apply py-4;

        }
      }
    }

    label {
      @apply flex items-center justify-between py-4 text-base font-bold text-clara;
      width: 100%;

      @media (min-width: 768px) {
        @apply text-2xl;
      }

      .arrow-icon {
        @apply p-1 bg-clara;
        border-radius: 4px;
        line-height: 1.5rem;

        .icon-container {
          @apply block;
          transition: transform 500ms ease-in-out;

          i {
            &:before {
              @apply text-blanco;
              font-size: 2rem;
            }
          }
        }
      }
    }

    .content {
      @apply text-oscura;

      overflow: hidden;

      transition: max-height 0.5s ease-in-out, margin-top 0.25s ease-in-out;

      @media (min-width: 768px) {
        @apply text-2xl;
      }

      p {
        @apply text-oscura;

        @media (min-width: 768px) {
          font-size: 20px;
        }
      }

      ul {
        list-style-type: disc;
        padding: revert;

        ul {
          list-style-type: circle;
        }
      }
    }
  }
}