/* Fixing SharePoint UI Styles */

.welcome-content {
  margin: 0;
}

/* TODO: Corregir este force para un espacio en blanco que SharePoint agrega */
.ms-rtestate-notify {
  margin-top: -5px;
}

.ms-signInLink {
  display: none !important;
}

#suiteBar img,
#s4-ribbonrow img {
  max-width: none;
  border-style: none;
}

#s4-workspace {
  @apply w-full;
  overflow-x: hidden;
}

#s4-bodyContainer {
  @apply p-0;

  .welcome {
    @apply p-0;
  }

  .ms-rtestate-field {
    &>p {
      @apply hidden;
    }

    p {
      @apply text-oscura;
      font-size: 21px;
      margin: 0;
      line-height: unset;
    }
  }

  .ms-webpartzone-cell {
    @apply m-0;
  }

  img {
    border: none;
  }

  input[type="button"],
  input[type="reset"],
  input[type="submit"],
  button {
    min-width: 0;
  }

  button {
    border: none;

    &:hover {
      border-color: transparent;
      background-color: transparent;
    }
  }

  a {
    font-family: "FreightSansProMedium";

    &:visited,
    &:active {
      /* @apply text-oscura; */
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
    }

    p {
      font-family: "FreightSansProMedium";
    }
  }

  .cta-button {
    @apply text-blanco;

    &:active,
    &:visited {
      @apply text-blanco;
    }
  }
}

.gslider {
  button {
    min-width: 0;
  }
}