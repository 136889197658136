.personal-spotlight {
  .article-text__head-title {
    @apply mb-0 text-clara;
    line-height: 1;

    @media (max-width: 767px) {
      @apply text-center;
    }

    @media (min-width: 1280px) {
      font-size: 45px;
    }
  }

  .article-text__head-sub-title {
    @apply text-oscura;
    line-height: 1;

    @media (max-width: 767px) {
      @apply text-center;
    }

    @media (min-width: 1280px) {
      font-size: 35px;
    }
  }

  .exec-info {
    @media (max-width: 767px) {
      @apply justify-center;
    }
  }
}
