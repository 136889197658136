#bono {
  .article-text {
    & > section {
      h3 {
        @apply text-clara font-bold;
        font-size: 21px;
        margin-bottom: 15px;

        @media (min-width: 768px) {
          font-size: 40px;
        }

        @media (min-width: 992px) {
          font-size: 25px;
        }
      }

      p,
      ol > li,
      ul > li {
        @apply text-oscura;
        font-size: 16px;
        line-height: normal;

        @media (min-width: 768px) {
          font-size: 21px;
        }
        @media (min-width: 992px) {
          font-size: 16px;
          line-height: 25px;
        }
      }

      ol {
        & > li {
          @apply relative flex flex-wrap text-oscura;
          font-family: "FreightSansProMedium";
          align-items: center;
          padding-left: 66px;
          margin-bottom: 44px;
          counter-increment: list;

          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            @apply absolute bg-clara text-blanco text-center;
            top: -5px;
            left: 0;
            width: 50px;
            height: 50px;
            font-family: "Arial";
            border-radius: 50%;
            font-size: 28px;
            padding-top: 10px;
            content: counter(list);
          }

          ul {
            @apply ml-6;
            flex: 1 0 100%;
            list-style-type: none;
          }

          ol {
            flex: 1 0 100%;
            list-style-type: auto;
          }
        }
      }

      ul {
        & > li {
          @apply relative flex flex-wrap text-oscura pl-4 py-2;
          font-family: "FreightSansProMedium";
          font-size: 1rem;

          &:before {
            @apply absolute mr-2 text-clara;
            left: 0;
            top: 16px;
            transform: translateY(-53%);
            content: "\2022";
            font-size: 1.5rem;
          }

          ul {
            @apply ml-6;
            flex: 1 0 100%;
            list-style-type: none;
          }

          ol {
            @apply ml-6;
            flex: 1 0 100%;
            list-style-type: auto;
          }
        }
      }

      .tabs-container {
        .react-tabs {
          &__tab-list {
            background-color: #f1f1f1;
            border-radius: 15px;
            padding: 9px;
            overflow: auto;
            white-space: nowrap;
            border-bottom: 0;
          }

          &__tab {
            background-color: #f1f1f1;
            font-family: "FreightSansProMedium";
            border-radius: 15px;
            font-size: 21px;
            padding: 19px 30px;
            color: #0c95c9;
            display: inline-block;
            border-color: transparent !important;

            &--selected {
              @apply bg-clara;
              color: #fff;
            }
          }
        }
        p {
          &:not(:last-child) {
            margin-bottom: 48px;
          }
        }
      }
    }
  }
}
