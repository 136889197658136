#s4-bodyContainer {
  .ms-rtestate-field {
    .footer {
      &__top {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 0px;
        background-color: #264e72;
        padding: 40px 35px 10px 35px !important;
      }

      &__bottom {
        background-color: #264e72;
        /* border-top: 3px solid #626266; */
        padding: 20px 40px;
        @media (min-width: 1280px) {
          padding: 25px;
          padding-top: 0;
          margin-bottom: 0;
        }
      }

      &__adress {
        margin-top: 48px;
        margin-bottom: 24px;

        @media (min-width: 768px) {
          margin: 0 0 40px 0;
        }

        @media (min-width: 1280px) {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }

      &__logo-box {
        @media (min-width: 768px) {
          margin-bottom: 40px;
        }
      }

      &__city {
        font-size: 20px;
        color: #ffffff;
        font-family: FreightSansProBold;
      }

      &__copy {
        margin-bottom: 20px;

        @media (min-width: 768px) {
          margin-bottom: 0;
        }
      }

      &__link {
        color: #ffffff !important;
        font-family: 'FreightSansProBook';
        font-size: 16px;
        line-height: 24px;
        transition: all 0.5s ease-out;

        &:hover,
        &:visited,
        &:active {
          color: #fff !important;
        }
      }

      &__closing-links {
        a,
        span {
          color: #fff;
          font-family: 'FreightSansProBook';
          font-size: 17px;

          @media (min-width: 1280px) {
            font-size: 18px;
          }

          &:hover {
            transition: all ease-in-out 0.5s;
            color: #6cd5ff;
          }
        }
      }

      &__disclaimer {
        color: #fff;
        font-family: 'FreightSansProBook';
        font-size: 16px;

        span {
          display: block;

          @media (min-width: 1280px) {
            display: inline-block;
          }
        }

        @media (min-width: 1280px) {
          font-size: 18px;
        }
      }

      &__text {
        font-size: 17px;
        color: #ffffff;
        font-family: 'FreightSansProBook';
        padding-left: 25px;
        position: relative;
        &--phone {
          margin-top: 24px;
          font-size: 17px;
        }
      }

      &__link-box {
        margin-bottom: 36px;

        @media (min-width: 768px) {
          margin-bottom: 0;
        }
      }

      &__cert-box {
        margin-top: 24px;
        display: flex;
        justify-content: flex-start;

        @media (min-width: 1280px) {
          justify-content: flex-start;
        }
      }

      /* TODO:BORRAR ESTE FORCE INNECESARIO */
      &__container-force {
        @media (max-width: 992px) {
          padding: 0 35px;
        }
        @media (min-width: 992px) {
          position: relative;
        }
      }

      &__social-box {
        @media (min-width: 1280px) {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
        }

        a {
          margin-right: 4px;

          @media (min-width: 768px) {
            margin-right: 12px;
          }

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            transition: all ease-in-out 1s;
            opacity: 1;

            img {
              transition: all ease-in-out 0.2s;
              transform: scale(1.2);
            }
          }
        }
      }

      &__link-list {
      }

      &__icon {
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
}
