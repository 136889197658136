.terms-container {
  .head-section {
    @apply flex justify-between items-center mb-5;

    @media (max-width: 1024px) {
      @apply flex-wrap;
    }

    .form {
      @media (max-width: 1024px) {
        @apply mt-6;
        flex: 1 1 100%;
      }

      input {
        @apply px-4 py-2 bg-blanco;
        border-radius: 8px;
        border: 2px solid #264e723d;

        background-image: url(../../assets/img/icons/search.svg);
        background-position: calc(100% - 0.5rem) 50%;
        background-repeat: no-repeat;

        @media (max-width: 1024px) {
          @apply w-full;
        }
      }
    }
  }

  .letter-filter {
    @apply flex justify-between py-6 px-12 mb-16 bg-gris;
    border-radius: 16px;

    @media (max-width: 1023px) {
      @apply hidden;
    }

    button {
      @apply text-grafito uppercase px-2;
      font-size: 22px;
      border-radius: 6px;

      &:disabled {
        @apply cursor-default;
        opacity: 0.6;
      }

      &:not(:disabled) {
        @apply font-semibold text-oscura;
      }

      &.active {
        @apply bg-oscura text-blanco;

        &:hover,
        &:focus,
        &:active {
          @apply bg-oscura text-blanco;
        }
      }
    }
  }

  .words-container {
    @apply grid grid-cols-1;
    column-gap: 8rem;
    row-gap: 3rem;

    @media (min-width: 640px) {
      @apply grid-cols-2;
    }

    @media (min-width: 1024px) {
      @apply grid-cols-3;
    }

    .letter {
      @apply border-b-4 border-gris;

      h1 {
        @apply text-oscura uppercase;
        font-size: 2.5rem;
      }
    }

    .words {
      li {
        @apply text-clara mt-5;
        font-size: 21px;
      }
    }

    .__react_component_tooltip {
      @apply p-6 bg-oscura;
      max-width: 350px;
      font-size: 23px;
      line-height: 37px;

      @media (min-width: 768px) {
        max-width: 700px;
      }
    }
  }
}
