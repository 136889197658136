.board-container {
  h3 {
    @apply mb-6 font-bold text-clara;
    font-size: 35px;
  }

  &__member-list {
    @apply grid grid-cols-1;
    gap: 15px;

    @media (min-width: 640px) {
      @apply grid-cols-2;
    }

    @media (min-width: 1024px) {
      @apply grid-cols-3;
    }

    @media (min-width: 1280px) {
      @apply grid-cols-3;
    }
  }

  &__member {
    @apply relative justify-center grid-cols-1 p-6;
    background-color: #f1f3f6;
    border-radius: 18px;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    display: flex;

    .img-container {
      @apply mb-2;
      img {
        @apply object-cover mx-auto;
        height: 128px;
        width: 128px;
        border-radius: 100%;
      }
    }

    .name {
      @apply text-center text-clara;
      font-size: 22px;
      font-family: "FreightSansProBold";
      /* height: 33px; */
    }

    .position {
      @apply text-center text-oscura;
      font-size: 21px;
      line-height: normal;
      font-family: "FreightSansProMedium";
    }

    i {
      @apply absolute;
      top: 1.5rem;
      right: 1.5rem;

      &:before {
        @apply text-clara;
        font-size: 1.5rem;

        @media (min-width: 768px) {
          font-size: 2rem;
        }
      }
    }
  }
}
