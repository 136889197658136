.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* FIXING ZOOM */
/* ------------------------------- HOME PAGE --------------------------------------- */


/* First Banner Slider */

@media (min-width: 1280px) {
  body .banner-position.Centralizado h2.banner-home__title, 
  body .banner-position.Derecha h2.banner-home__title, 
  body .banner-position.Izquierda h2.banner-home__title {
      font-size: 40px !important;
      line-height: 41px !important;
  }
}


@media (min-width: 1280px) {
  body #s4-bodyContainer .welcome-section__text-block h2 {
      font-size: 34px !important;
      line-height: 44px !important;
  }
}

@media (min-width: 1200px) {
    body .banner-top__title {
        font-size: 34px !important;
    }
}

body #s4-bodyContainer .ms-rtestate-field p,
body #s4-bodyContainer .welcome-section__text-block p,
body .custom-list.number-list>li {
  font-size: 18px !important;
  line-height: 24px !important;
  font-family: "FreightSansProMedium" !important;
  @media (max-width: 768px) {
    line-height: 29px !important;
  }
}

#s4-bodyContainer .ms-rtestate-field p {
    font-size: 18px;
}

/* HOME - Por que Nosotros*/
@media (min-width: 1280px) {
  #s4-bodyContainer .generic-title--record {
      font-size:36px !important;
      line-height: 44px;
  }
}

@media (min-width: 1280px) {
  #s4-bodyContainer .generic-title--record span {
      font-size: 22px;
      line-height: 38px;
  }
}

@media (min-width: 1280px) {
  #s4-bodyContainer .record .generic-button {
      padding: 13px 60px !important;
  }
}
/* ------------------------------------------------------- */


/* ¿Qué te ofrecemos? */

@media (min-width: 768px) {
  #s4-bodyContainer .record__number {
      font-size: 50px;
  }
}

@media (min-width: 1280px) {
  #s4-bodyContainer .generic-button, 
  #s4-bodyContainer a.generic-button, 
  #s4-bodyContainer buttton.generic-button {
      font-size: 18px !important;
  }
}

@media (min-width: 1280px) {
  #s4-bodyContainer .generic-title span {
      font-size: 22px !important;
  }
}

#s4-bodyContainer .ms-rtestate-field p {
  font-size: 18px;
  line-height: 24px;
}

@media (min-width: 1280px) {
  .offer__tab {
      font-size: 18px;
  }
}

/* fOOTER */

#s4-bodyContainer .ms-rtestate-field .footer__text {
  font-size: 15px;
}

#s4-bodyContainer .ms-rtestate-field .footer__link {
  font-size: 15px;
}

#s4-bodyContainer .ms-rtestate-field .footer__text--phone {
  font-size: 15px;
}

@media (min-width: 1280px) {
  #s4-bodyContainer .ms-rtestate-field .footer__disclaimer {
      font-size: 15px;
  }
}
@media (min-width: 1280px) {
  #s4-bodyContainer .ms-rtestate-field .footer__closing-links a, 
  #s4-bodyContainer .ms-rtestate-field .footer__closing-links span {
      font-size: 15px;
  }
}

/* ---------------------- CONTENT SECTIONS ------------------------------ */
/* Nosotros */

@media (min-width: 768px) {
  #s4-bodyContainer .article-text__head-title {
      font-size: 34px !important;
  }
}

body .side-menu__title {
  font-size: 23px !important;
}
.side-menu__bottom-list a.active,
.side-menu__bottom-list a {
  font-size: 18px !important;
}

.side-menu__bottom-list .sub-acordeon .trigger-sub-accordeon {
    font-size: 18px !important;
}

body .custom-list.number-list>li {
    padding-left: 54px !important;
}
#fideicomiso .description li {
    font-size: 18px!important;
    line-height: 24px!important;
}

body .side-menu__bottom-list .sub-acordeon .trigger-sub-accordeon {
    font-family: "FreightSansProMedium";
    font-size: 18px !important;
}

body .custom-list.number-list>li:before {
    width: 40px !important;
    height: 40px;
    font-size: 21px !important;
    padding-top: 8px !important;
}


body .media__img__icon {
    padding: 8px !important;
}

/* --------------------- CONOCE MAS ---------------- */

/* QUE ES UN FIDEICOMISO */
#sub-bar .container #sub-bar-slider .splide__track .splide__list .splide__slide .slide-content span {
  font-size: 19px !important;
}
@media (min-width: 768px) {
  .toggle-list__item label {
      font-size: 20px !important;
      line-height: 2rem;
  }
}

.custom-list.check-list>li:before {
  font-size: 1.55rem !important;
  top: -3px !important;
}

#s4-bodyContainer .ms-rtestate-field p {
  font-size: 18px;
  line-height: 25px;
  font-family: "FreightSansProMedium" !important;
}

/* MULTIMEDIA */

.media-container .media__img__icon [class*=" icon-"], 
.media-container .media__img__icon [class^=icon-] {
  display: none ;
}

.media__img__icon {
  width: 126px !important;
  height: 126px !important;
}

/* Multimedia filter */
.head-section form label {
  font-size: 15px !important;
}

.head-section form select {
  font-weight: 600;
  font-size: 16px;
}

.media__img__icon [class*=" icon-"], .media__img__icon [class^=icon-] { 
   background-position: 29px center;
}

.custom-list.check-list>li{
  font-size: 18px !important;
  font-family: "FreightSansProMedium" !important;
}

/* Marco Legal */

.documents__filter label {
  font-size: 17px;
}

.documents__filter input{
  font-size: 17px;
}

.documents__filter__fields.global-container {
  margin-top: -6px !important;
}


/* ----------------------------Miembros Modal ---------------------------------------------*/


@media (max-width: 680px) {
  .member-modal__content-wrapper.hide-on-mobile {
    display: none;
  }
  .member-modal__content-wrapper.hide-on-desktop {
    margin-top: 40px;
    display: block;
  }  
  /*
 .adressmodal__close {
    border:solid 3px green;
  } */
  .adressmodal__close {
    color: #ccc;
    font-size: 36px;
    right: 5px;
    top: 10px;
  }
  .adressmodal__close .feather {
    font-size: 35px;
    opacity: 1;
  }
  
}

@media(min-width:681px){
  .member-modal__content-wrapper.hide-on-desktop {
    margin-top: 50px;
    display: none;
  } 
}

@media (min-width: 681px) and (max-width: 1279px) {
   
  .member-modal__content-wrapper.hide-on-mobile {
    display:block !important;
  }
   .member-modal__text:last-child {
    margin-top: 22px;
    }
    /*
  .adressmodal__close {
    border:solid 3px red;
  }*/

  .member-modal__name {
    font-size: 29px !important;
  }
  .member-modal__position {
    font-size: 20px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1800px){
 
  .adressmodal__close {
    color: #ccc;
    top: 10px;
}

  .member-modal__content-wrapper.hide-on-mobile {
    display:block !important;
  }
  .member-modal__name {
    margin-top: 0;
    font-size: 24px;
  }

  .member-modal__position {
    margin-top: 0;
    font-size: 20px;
  }
  /*
  .adressmodal__close {
    border:solid 3px blue;
  }*/
  .member-modal__name {
    margin-top: 0;
    font-size: 40px;
  }
  .member-modal__position {
    margin-top: 0;
    font-size: 28px;
  }
}

/* END */


@media (min-width: 1201px){
  .member-modal__content-wrapper.hide-on-mobile {
    display:block !important;
  }
}



.adressmodal__close .feather {
  font-size: 40px;
  opacity: 1;
}

/* FIXING MOBILE */
/* ------------------------------- HOME PAGE --------------------------------------- */

.custom-list.bullet-list>li:before {
  top: 18px !important;
}


@media (max-width: 680px) {
  .relative.flex.overflow-y-hidden.overflow-x-hidden:after {
      display:none;
  }
}


.media-container .media__img__icon [class*=" icon-"]:before {
  display: none;
}

.media__img__icon [class*=" icon-"]{
  background-position: 60% !important;
}

.head-section form select {
  font-size: 17px!important;
}

.head-section form select {
  font-weight: normal !important;
}

.letter-filter .letter:first-child{
  font-size: 16px !important;
  padding: 5px 16px !important;
  background: #00aef0 !important;
}

.project__select {
  font-size: 18px !important;
}

.project .project-right h4 {
  color: #fff !important;
}

#s4-bodyContainer .article-text__parra {
  font-size: 18px !important;
  line-height: 24px !important;
}

#s4-bodyContainer .article-text__parra ol li, 
#s4-bodyContainer .article-text__parra ul li {
  font-size: 18px !important;
  line-height: 24px !important;
}

@media (max-width:768px) {
  body #s4-bodyContainer .document__desc p {
    font-family: "FreightSansProBold" !important;
    font-size: 20px !important;
  }
}