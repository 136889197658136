.tabs-container {
  &.single-list {
    .single-list-title {
      @apply block;
    }

    .react-tabs {
      &__tab-list {
        @apply hidden;
      }
    }
  }

  .single-list-title {
    @apply hidden;
  }

  .react-tabs {
    &__tab-list {
      background-color: #f1f1f1;
      border-radius: 15px;
      padding: 9px;
      overflow: auto;
      white-space: nowrap;
      border-bottom: 0;
    }

    &__tab-panel {
      padding: 32px;
    }

    &__tab {
      background-color: #f1f1f1;
      font-family: "FreightSansProMedium";
      border-radius: 15px;
      font-size: 21px;
      padding: 19px 30px;
      color: #0c95c9;
      display: inline-block;
      border-color: transparent !important;

      &--selected {
        @apply bg-clara;
        color: #fff;
      }
    }
  }
}
