#sub-bar {
  @apply py-8 bg-oscura;

  .container {
    @apply mx-auto;
    #sub-bar-slider {
      @apply mx-auto;
      max-width: calc(100vw - 8rem);

      .splide__arrows {
        .splide__arrow {
          background-color: transparent;

          &--prev {
            left: -3rem;
          }

          &--next {
            right: -3rem;
          }

          i {
            &:before {
              @apply text-blanco;
              font-size: 4rem;
            }
          }
        }
      }
      .splide__track {
        .splide__list {
          .splide__slide {
            .slide-content {
              @apply flex items-center gap-4 px-6 py-4;

              border-color: #9fb4c5;
              border-width: 3px;
              border-radius: 14px;

              &.active {
                @apply border-blanco bg-blanco;

                span {
                  @apply text-clara;
                }
              }

              img {
                @apply object-contain;
                width: 48px;
                height: 48px;
              }

              span {
                @apply text-blanco font-bold;
                font-size: 21px;
              }
            }
          }
        }
      }
    }
  }
}
