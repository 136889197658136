.documents {
  &__select {
    margin-right: 12px;
    border: 2px solid #eaeaeae3;
    border-radius: 10px;
    font-family: "FreightSansProMedium";
    @apply text-clara;
    background-image: url(../../assets/img/chevron-down.svg);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-position: 95% 50%;
    padding: 12px 25px;
    font-size: 21px;
    padding-right: 50px;
    padding-left: 25px;
    appearance: none;
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 12px;

    &--black {
      color: #264e72;
      background-image: url(../../assets/img/chevron-down2.svg);
    }

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: 1280px) {
      display: inline-block;
      width: auto;
    }
  }

  &__filter {
    @apply flex flex-wrap justify-between items-center mt-4 py-6 px-8 gap-4;
    border-radius: 14px;
    box-shadow: 2px 2px 16px #1a43591a;

    @media (min-width: 1280px) {
      @apply flex-nowrap gap-6;
    }

    label {
      @apply text-oscura;
      font-family: "FreightSansProBook";
      font-size: 19px;

      @media (max-width: 1279px) {
        flex: 1 0 100%;
      }

      @media (min-width: 1600px) {
        font-size: 21px;
      }
    }

    input,
    select {
      @apply px-4 py-2 bg-blanco text-clara;
      flex: 0 1 225px;
      border-radius: 8px;
      border: 2px solid #264e723d;
      font-size: 21px;

      @media (max-width: 1279px) {
        flex: 1 1 225px;
      }
    }

    &__fields {
      @apply flex justify-between gap-4 max-w-full;
      flex: 1 1 auto;

      @media (max-width: 1279px) {
        @apply flex-wrap justify-between gap-6;
      }

      &.global-container {
        @apply justify-end;

        flex: 0 1 0;

        @media (max-width: 1279px) {
          flex: 1 0 100%;
        }

        .global {
          @apply max-w-full pl-12;
          background-image: url(../../assets/img/icons/search.svg);
          background-position: 0.5rem 50%;
          background-repeat: no-repeat;

          @media (max-width: 1280px) {
            flex: 1 1 100%;
          }
        }
      }

      .property-container {
        @apply flex items-center gap-8;
        @media (max-width: 700px) {
          @apply flex-col items-start gap-4;
        }

        .filter-property {
          @apply flex items-center text-oscura;
          font-size: 21px;

          input {
            @apply mr-5;
            transform: scale(2.5);
          }
        }
      }

      .field-container {
        @apply flex items-center;
        flex: auto;
      }
    }
  }

  &__list {
    @apply mt-6;
    &__section {
      @apply mt-6;

      h4 {
        @apply text-oscura font-bold mb-4;
        font-size: 24px;
      }

      .files {
      }
    }
  }
}
