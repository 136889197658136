.info-text {
  @apply flex gap-12 mt-6;

  @media (max-width: 768px) {
    @apply flex-wrap;
  }

  .img-container {
    flex: 1 1 100%;

    @media (min-width: 768px) {
      flex: 1 1 30%;
    }

    img {
      @apply mx-auto;
      border-radius: 20px;
    }
  }

  .side-text-container {
    @apply text-oscura;
    font-family: "FreightSansProMedium";
    flex: 1 1 100%;

    @media (min-width: 768px) {
      flex: 1 1 70%;
    }

    p {
      @media (min-width: 1024px) {
        font-size: 1.25rem;
      }
    }
  }
}
