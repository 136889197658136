.hover-profile-card {
  @apply relative flex flex-wrap justify-center content-center items-center bg-blanco text-center p-16;
  border-radius: 14px;
  height: 400px;

  transition: padding 0.5s ease-in-out;

  @media (max-width: 640px) {
    @apply p-1;
  }

  @media (min-width: 1280px) {
    @apply p-2;
  }

  @media (min-width: 1600px) {
    @apply p-4;
  }

  &:hover {
    .hover-profile-card__photo {
      width: 144px;
      height: 144px;

      @media (min-width: 769px) {
        width: 96px;
        height: 96px;
      }

      @media (min-width: 1281px) {
        width: 144px;
        height: 144px;
      }
    }

    .hover-profile-card__info {
      max-height: 100%;
      color: #264e72;
      font-size: 1rem;

      @media (max-width: 1024px) {
        font-size: 14px;
      }

      @media (width: 1280px) {
        font-size: 14px;
      }
    }
  }

  &:not(:first-child) {
    &:before {
      @media (max-width: 767px) {
        @apply block absolute transform rotate-90;
        content: "";
        width: 48px;
        height: 48px;
        top: -5.5rem;
        background-image: url(../../../assets/img/arrow-right.svg);
      }

      @media (min-width: 1024px) {
        @apply block absolute;
        content: "";
        width: 48px;
        height: 48px;
        left: -5.5rem;
        background-image: url(../../../assets/img/arrow-right.svg);
      }
    }
  }

  &__photo {
    @apply object-cover;
    width: 192px;
    height: 192px;
    border-radius: 100%;

    transition: width 500ms ease-in-out, height 500ms ease-in-out;
  }

  &__name {
    @apply text-clara font-bold text-2xl w-full my-4;
  }

  &__info {
    max-height: 0;
    overflow: hidden;
    font-size: 21px;

    transition: max-height 0.5s ease-in-out;
  }
}
