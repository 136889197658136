#quienes-somos {
  .content {
    padding: 0;
    margin-top: 24px;

    @media (min-width: 1280px) {
      margin: 60px 0 30px;
    }

    h1 {
      color: #00aef0;
      font-family: FreightSansProBold;
      font-size: 25px;

      @media (min-width: 768px) {
        font-size: 30px;
        margin-bottom: 10px;
      }
    }

    p {
      color: #264e72;
      font-family: "FreightSansProMedium";
      font-size: 18px;
      line-height: 29px;

      @media (min-width: 768px) {
        font-size: 22px;
      }
    }
    ul {
      padding-left: 24px;
      position: relative;

      li {
        font-family: "FreightSansProMedium";
        font-size: 18px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        color: #264e72;

        &:before {
          content: "";
          position: absolute;
          height: 10px;
          width: 10px;
          @apply bg-clara;
          border-radius: 50%;
          left: 0;
          margin-top: 8px;
        }
      }
    }
  }
}
