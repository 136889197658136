.project {
  padding: 70px 0;

  &--noticias {
    padding-bottom: 0;
  }

  .container {
    padding: 0 30px;

    @media (min-width: 1280px) {
      padding: 0;
    }
  }

  &__filter-text {
    font-family: 'FreightSansProMedium';
    font-size: 21px;
    display: block;
    margin-bottom: 6px;
    @apply text-oscurisima;

    @media (min-width: 1280px) {
      display: inline-block;
      margin-right: 14px;
      margin-bottom: 0;
    }
  }

  .project-price {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border-left: 16px solid #00aef0;
    padding: 36px;
    padding-left: 48px;
    bottom: 98px;

    &__tag {
      font-family: 'FreightSansProBold';
      font-size: 19px;
      color: #264e72;
    }

    &__number {
      font-family: 'FreightSansProMedium';
      color: #264e72;
      font-size: 27px;
      margin-bottom: -10px;
    }
  }

  &__info {
    @apply mt-8;
    margin-bottom: 30px;
  }

  .project-slide {
    .splide__slide {
      border-radius: 15px;
      position: relative;
    }
  }

  .thumbs {
    margin-top: 36px;

    .splide__slide {
      border-radius: 15px;
      border: none !important;
    }

    .splide--nav>.splide__track>.splide__list>.splide__slide.is-active {
      border: none !important;
    }
  }

  &__select {
    margin-right: 12px;
    border: 2px solid #eaeaeae3;
    border-radius: 10px;
    font-family: 'FreightSansProMedium';
    @apply text-clara;
    background-image: url(../../assets/img/chevron-down.svg);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-position: 95% 50%;
    padding: 12px 25px;
    font-size: 21px;
    padding-right: 50px;
    padding-left: 25px;
    appearance: none;
    position: relative;
    display: block;

    margin-bottom: 12px;

    &--black {
      color: #264e72;
      background-image: url(../../assets/img/chevron-down2.svg);
    }

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: 1280px) {
      display: inline-block;
    }
  }

  &__icon-search {
    display: block;
    position: relative;

    @media (min-width: 1280px) {
      display: inline-block;
      width: auto;
    }
  }

  &__input {
    margin-right: 23px !important;
    border: 2px solid #eaeaeae3 !important;
    border-radius: 10px;
    font-family: 'FreightSansProMedium';
    color: #00aef0;
    background-image: url(../../assets/img/search.svg);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: 95% 8px !important;
    padding: 12px 25px !important;
    font-size: 21px;
    padding-right: 50px !important;
    padding-left: 25px !important;
    appearance: none;
    position: relative;
    display: block !important;
    width: 100% !important;
    margin-bottom: 12px;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    @media (min-width: 1280px) {
      display: inline-block !important;
      width: auto !important;
    }
  }

  &__icon-search {
    display: block;
    position: relative;

    @media (min-width: 1280px) {
      display: inline-block;
      width: auto;
    }
  }

  &__input {
    margin-right: 23px;
    border: 2px solid #eaeaeae3;
    border-radius: 10px;
    font-family: 'FreightSansProMedium';
    color: #00aef0;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-position: 95% 14px;
    background-image: url(../../assets/img/chevron-down.svg);
    padding: 12px 25px;
    font-size: 21px;
    padding-right: 50px;
    padding-left: 25px;
    appearance: none;
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 12px;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    @media (min-width: 1280px) {
      display: inline-block;
      width: auto;
    }
  }

  &__filter {
    border-bottom: 2px solid #ececece1;
    padding-bottom: 30px;
    padding-top: 15px;

    @media (min-width: 768px) {
      border-bottom: 4px solid #ececece1;
    }

    &--prensa {
      .project__select {
        @media (min-width: 1280px) {
          margin-bottom: 0;

          .project-head__head-title {
            margin: 0;
          }
        }
      }
      .project__filter{
        border:none !important;
      }
    }
  }

  .project-left {
    background-color: #f3f3f6;
    border-radius: 10px;
    padding: 30px;
    height: 100%;
  }

  .project-right {
    @apply bg-oscurisima;
    border-radius: 10px;
    padding: 30px;
    margin: 0;
    height: 100%;

    h3,
    h4,
    h5,
    h5>a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: white;
    }

    h3 {
      font-family: 'FreightSansProBold';
      color: #fff;
      font-size: 24px;

      @media (min-width: 1280px) {
        font-size: 33px;
      }
    }

    h4 {
      font-family: 'FreightSansProMedium';
      @apply text-clara;
      margin-bottom: 12px;
      font-size: 22px;
    }

    h5 {
      color: #fff;
      font-family: 'FreightSansProMedium';
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-size: 20px;

      span {
        @apply text-clara;
        font-size: 24px;
        margin-right: 12px;
      }
    }
  }

  &__info-title {
    font-family: 'FreightSansProBold';
    @apply text-clara;
    font-size: 24px;

    @media (min-width: 1280px) {
      font-size: 33px;
    }
  }

  &__info-details {
    div {
      margin-top: 24px;

      @media (min-width: 1280px) {
        max-width: 33.33%;
        flex: 0 0 33.33%;
      }
    }

    h3 {
      font-family: 'FreightSansProBold';
      font-size: 20px;
      @apply text-oscurisima;
    }

    p {
      font-family: 'FreightSansProMedium';
      font-size: 18px;
      @apply text-oscurisima;
    }
  }
}

.project-head {
  @media (min-width: 1280px) {
    padding-top: 0;
  }

  .head-section {
    text-align: center;
    margin-bottom: 36px;
    border-bottom: 2px solid #ececece1;
    margin-bottom: 32px;
    padding-bottom: 32px;

    @apply justify-center;
    @apply justify-between md:text-left;

    @media (min-width: 768px) {
      border-bottom: 4px solid #ececece1;
    }

    @media (min-width: 1280px) {
      text-align: left;
    }

    &__city {
      display: inline-flex;
      align-items: center;
      @apply text-oscurisima !important;

      span {
        @apply text-clara;
        font-size: 24px;
        margin-right: 12px;

        @media (min-width: 1280px) {
          font-size: 30px;
        }
      }

      strong {
        margin-right: 5px;
      }

      font-size: 18px;
      font-family: 'FreightSansProMedium';

      @media (min-width: 1280px) {
        font-size: 23px;
      }
    }

    &__back {
      font-size: 18px;
      font-family: 'FreightSansProMedium';
      @apply text-clara;
      text-alig: center;
      display: inline-flex;
      align-items: center;

      span {
        font-size: 28px;
        margin-right: 6px;
      }

      span {
        font-size: 24px;
      }
    }
  }

  &__help {
    display: flex;
    margin-top: 36px;
  }

  &__readmore {
    color: #00aef0;
    font-size: 22px;
    font-family: 'FreightSansProMedium';
  }

  &__help-icon {
    font-size: 64px;
    color: #00aef0;
    margin-right: 14px;
  }

  &__help-text {
    h4 {
      @apply text-oscurisima;
      font-family: 'FreightSansProMedium';
      font-size: 24px;
    }

    a {
      color: #07a6e2;
      font-family: 'FreightSansProMedium';
    }
  }

  &__image {
    margin: 31px 0;
  }

  &__head-title {
    color: #00aef0;
    font-family: FreightSansProBold;
    font-size: 26px;

    @media (min-width: 768px) {
      font-size: 40px;
      margin-bottom: 15px;
    }

    &--center {
      text-align: center;
      margin-bottom: 12px;

      @media (min-width: 768px) {
        text-align: left;
      }
    }
  }

  &__head-sub-title {
    @apply text-oscura;
    font-family: FreightSansProBold;
    font-size: 21px;

    @media (min-width: 768px) {
      font-size: 35px;
    }
  }

  &__title {
    color: #00aef0;
    font-family: FreightSansProBold;
    font-size: 25px;

    @media (min-width: 768px) {
      font-size: 30px;
      margin-bottom: -5px;
    }
  }

  &__parra {
    color: #264e72;
    font-family: 'FreightSansProMedium';
    font-size: 18px;
    margin-bottom: 49px;
    line-height: 38px;

    @media (min-width: 768px) {
      font-size: 22px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list {}

  &__list-item {
    &:before {
      @apply mr-4 text-clara;
      content: '\2022';
      font-size: 1.5rem;
    }
  }

  &__document {
    margin-top: 46px;
  }

  &__document-info {
    margin-top: 12px;

    @media (min-width: 1280px) {
      margin-top: 0;
    }
  }

  &__document-title {
    color: #264e72;
    font-family: FreightSansProBold;
    font-size: 18px;
    margin-bottom: 6px;

    @media (min-width: 1280px) {
      font-size: 25px;
    }
  }

  &__document-text {
    color: #264e72;
    font-family: 'FreightSansProMedium';
    opacity: 0.53;
    font-size: 18px;
    margin-bottom: 6px;

    @media (min-width: 1280px) {
      font-size: 19px;
    }
  }

  &__document-img {
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;

    @media (min-width: 768px) {
      width: 167px;
      height: 207px;
      object-fit: cover;
      margin-right: 24px;
    }
  }

  &__organigrama {}

  &__organigrama-filial {
    padding: 20px;
    margin-bottom: 40px;
    background-color: #f1f3f6;
    border-radius: 10px;
    position: relative;
    transition: all ease-out 0.5s;
    cursor: pointer;

    &:first-of-type {
      background-color: #264e72;

      p {
        color: #fff;
      }
    }

    &--small {
      width: 85%;
      margin-left: auto;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 4px;
        width: 35px;
        background-color: #e6e6e6;
        right: 100%;
        top: 50%;
      }

      &:after {
        content: '';
        position: absolute;
        height: 150%;
        width: 4px;
        background-color: #e6e6e6;
        left: -35px;
        bottom: 50%;
        border-radius: 5px;
        z-index: -1;
      }
    }
  }

  &__organigrama-link {
    display: block;
    text-align: right;
    bottom: 12px;
    color: #159cd1;
    position: absolute;
    font-size: 18px;
    right: 12px;

    @media (min-width: 1280px) {
      display: inline-block;
      text-align: right;
      color: #159cd1;
      font-size: 27px;
      right: 15px;
      bottom: 15px;
    }
  }

  &__image-box {
    background-color: #fff;
    margin-bottom: 12px;
    border-radius: 10px;
    height: 139px;

    @media (min-width: 768px) {
      max-width: 50%;
      flex: 0 0 50%;
      margin-bottom: 0;
    }
  }

  &__organigrama-image {
    /* width: 399px;
      height: 139px; */
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__organigrama-text {
    @media (min-width: 768px) {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 0 0 0 24px;
    }
  }

  &__organigrama-desc {
    font-family: 'FreightSansProMedium';
    color: #264e72;
    font-size: 18px;
  }

  &__organigrama-image {
    border-radius: 10px;
  }

  &__document-download {
    color: #00aef0;
    font-family: 'FreightSansProMedium';
    font-size: 18px;
    margin-bottom: 6px;
    align-items: center;

    &:hover {
      color: #264e72;
    }

    @media (min-width: 1280px) {
      font-size: 19px;
    }

    span {
      margin-right: 6px;
      font-size: 23px;
    }
  }
}

.project-list {
  padding: 60px 0;

  &--slide {
    .splide__pagination {
      bottom: auto !important;
      margin-top: 36px;

      .splide__pagination__page {
        background-color: #bbc3cb;
        width: 8px;
        height: 8px;
        opacity: 1;
      }

      .splide__pagination__page.is-active {
        background-color: #00aef0;
        width: 8px;
        height: 8px;
      }
    }

    .splide__slide {
      /* @media (min-width: 768px) {
        padding-right: 2rem;
      }

      @media (min-width: 1280px) {
        padding-right: 2.25rem;
      } */
    }
  }

  @media (min-width: 768px) {
    padding: 30px 0;
  }

  &__building {
    background-color: rgba(238, 239, 243, 0.8);
    border-radius: 12px;
    margin-bottom: 24px;
    height: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  &__top-box {
    position: relative;
  }

  &__image {
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &__bottom-box {
    position: relative;
    padding: 15px;

    @media (min-width: 768px) {
      padding: 30px;
    }
  }

  &__name {
    font-family: 'FreightSansProBold';
    font-size: 28px;
    color: #264e72;
    margin-bottom: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @media (min-width: 768px) {
      font-size: 27px;
      line-height: 30px;
    }
  }

  &__builder {
    font-family: 'FreightSansProMedium';
    font-size: 18px;
    margin-bottom: 6px;
    @apply text-oscurisima;

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  &__city {
    @apply flex;
    @apply text-oscurisima !important;
    font-family: 'FreightSansProMedium';
    align-items: center;
    font-size: 18px;

    span {
      @apply text-clara;
      margin-right: 12px;
    }
  }

  &__tag {
    background: #ffffff;
    position: absolute;
    border-radius: 6px;
    padding: 8px 12px;
    color: #264e72;
    top: 18px;
    font-family: 'FreightSansProBold';
    left: 12px;
    font-size: 16px;
    text-align: center;
    min-width: 182px;

    @media (min-width: 768px) {
      font-size: 20px;
    }

    &--blue {
      color: #fff;
      @apply bg-clara;
    }
  }
}

#s4-bodyContainer {
  .project-pagination {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: 16px;

    &--noticias {
      margin-bottom: 63px;
    }

    @media (min-width: 768px) {
      font: size 20px;
    }

    &__number {
      @apply bg-clara;
      font-family: 'FreightSansProMedium';
      color: #fff;
      padding: 5px 13px;
      border-radius: 7px;
      margin: 0 4px;

      @media (min-width: 768px) {
        padding: 5px 17px;
        margin: 0 6px;
        font-size: 20px;
      }

      &:hover {
        @apply bg-clara text-blanco;
      }

      &--action {
        @apply text-clara;
        background-color: transparent;

        @media (min-width: 768px) {
          margin: 0 24px;
        }
      }

      &--active {
        background-color: #f1f3f6;
        @apply text-clara;

        &:hover {
          background-color: #f1f3f6 !important;
        }
      }
    }
  }
}

.status-tag {
  @apply bg-clara;
  color: #fff;
  font-family: 'FreightSansProMedium';
  font-size: 21px;
  border-radius: 10px;
  padding: 10px;
  position: relative;

  .circle {
    font-size: 14px;
  }
}

.project-list .project-list__builder {
  font-size: 18px !important;
}

.ms-rtestate-field h4,
h4.ms-rteElement-H4 {
  color: #00AEF0 !important;
}

.ms-rtestate-field .noticias__date,.ms-rtestate-field .noticias__date h4 {
  font-family: "FreightSansProMedium";
  color: #264e72!important;
}