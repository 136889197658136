#s4-bodyContainer {
  .file {
    @apply flex justify-between items-center bg-gris p-6;
    border-radius: 14px;

    @media (max-width: 768px) {
      @apply flex-wrap gap-4;
    }
    
    @media (min-width: 1024px) {
      gap: 4rem;
    }

    &:not(:last-child) {
      @apply mb-6;
    }

    &__info {
      @apply flex items-center gap-2;
      flex: 1 1 auto;

      @media (max-width: 768px) {
        @apply justify-center flex-wrap;
        flex: 1 1 100%;
      }

      @media (min-width: 1024px) {
        @apply gap-8;
        flex: 1 1 45%;
      }

      .file-type-container {
        flex: 1 0 50px;

        @media (max-width: 640px) {
          @apply flex justify-start;
          flex: 1 0 100%;
        }
      }

      .file-name-container {
        flex: 1 1 100%;
      }

      &__name {
        font-family: 'FreightSansProMedium';
        font-size: 18px;
        @media (min-width: 1024px) {
          font-size: 22px;
        }
      }

      &__details {
        @apply mt-4;
        font-size: 14px;
        @apply text-oscurisima;
        font-family: 'FreightSansProBook';
        min-width: 290px;
        @media (min-width: 1200px) {
          @apply hidden;
        }
      }
    }

    &__details {
      flex: 1 1 auto;
      @media (max-width: 1200px) {
        @apply hidden;
      }

      p {
        color: #264e72 !important;
        font-family: 'FreightSansProBook';
        font-size: 20px;
      }
    }

    &__download-link {
      @apply flex justify-center;
      flex: 1 0 100%;

      @media (min-width: 640px) {
        @apply justify-end;
        flex: 1 0 252px;
      }

      a {
        @apply px-8 py-3 text-blanco bg-clara;
        border-radius: 10px;
        font-size: 19px;
        font-family: 'FreightSansProMedium';
      }
    }
  }

  p{
    color: #264e72 !important;
  }
}
