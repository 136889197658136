.contacto {
  padding-left: 40px;
  padding-right: 40px;

  &__info {
    background-color: #fff;
    box-shadow: 0px 0px 46px #00000010;
    padding: 24px;
    border-radius: 20px;
    display: flex;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      margin: 0;
    }
  }

  &__row {
    &--info {
      @media (min-width: 1280px) {
        margin-top: 68px;
      }
    }
  }

  &__icon {
    margin-right: 12px;
    color: #00aef0;
    font-size: 24px;
    margin-top: 6px;

    @media (min-width: 1280px) {
      font-size: 36px;
    }
  }

  &__text {
    flex: 1;

    text-overflow: ellipsis;
    overflow: hidden;
    flex-wrap: nowrap;
    

    h3 {
      font-family: 'FreightSansProBold';
      --tw-text-opacity: 1;
      color: #264e72;
      font-size: 20px;

      @media (min-width: 1280px) {
        font-size: 26px;
      }
    }

    p {
      font-family: 'FreightSansProMedium';
      --tw-text-opacity: 1;
      color: rgba(27, 58, 87, var(--tw-text-opacity));
      font-size: 16px;
      margin-top: 6px;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-wrap: nowrap;

      @media (min-width: 1280px) {
        font-size: 22px;
        line-height: 30px;
      }
    }

    a {
      --tw-text-opacity: 1;
      color: rgba(0, 174, 240, var(--tw-text-opacity));
    }

    &__email {
      font-size: 18px;
    }
  }

  .success-alert {
    @apply flex justify-between gap-8 p-8 mb-6;
    background-color: #e7f8ed;
    border-color: #c3e4ce;
    border-radius: 12px;

    &:not(.active) {
      @apply hidden mb-0;
    }

    .icon-container {
      span {
        @apply flex justify-center items-center bg-blanco p-2;
        border-radius: 100%;

        &:before {
          color: #00b74e;
          font-size: 48px;
        }
      }
    }

    .message {
      p {
        @apply text-oscura;
      }
    }

    .button-container {
      button {
        font-size: 48px;
      }
    }
  }
}

.map {
  &__text {
    font-family: 'FreightSansProMedium';
    --tw-text-opacity: 1;
    color: rgba(27, 58, 87, var(--tw-text-opacity));
    font-size: 16px;
    margin-top: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;

    @media (min-width: 1280px) {
      font-size: 22px;
    }

    &--blue {
      --tw-text-opacity: 1;
      color: rgba(0, 174, 240, var(--tw-text-opacity));

      font-size: 15px;
      margin-top: 0;

      @media (min-width: 1280px) {
        font-size: 20px;
      }

      a {
        &:visited,
        &:active,
        &:focus {
          @apply text-clara;
        }
      }
    }
  }

  &__image {
    border-radius: 20px;
    margin: 19px 0;
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.contact-title {
  font-family: 'FreightSansProBold';
  font-size: 33px;
  --tw-text-opacity: 1;
  color: rgba(38, 78, 114, var(--tw-text-opacity));
  margin-bottom: 24px;

  span {
    font-size: 18px;
    font-family: 'FreightSansProMedium';
    color: #264e72;
    display: block;
  }
}

.contact-info {
  .form-control {
    margin-bottom: 12px;
  }

  .generic-button {
    width: 100%;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  input.invalid {
    background-color: #fff5f5 !important;
    border: 1px solid #c1634d !important;
  }

  .invalid-text {
    font-size: 1rem;
    color: #9f2c28;
  }
}
