.hover-background-card {
  @apply relative;

  &__image {
    @apply w-full h-full object-cover;
    border-radius: 14px;
  }

  &__info {
    @apply absolute px-4 py-2 bg-blanco;
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    border-radius: 14px;

    transition: padding 250ms ease-in-out;

    h4 {
      @apply text-clara font-bold;
      font-size: 1rem;

      @media (min-width: 1024px) {
        font-size: 1.25rem;
      }
    }

    &:hover {
      @media (min-width: 1280px) {
        @apply py-2;
      }

      @media (min-width: 1600px) {
        @apply py-2;
      }

      .hover-background-card__info__expanded {
        max-height: 400px;
      }
    }

    &__expanded {
      max-height: 0px;
      overflow: hidden;

      transition: max-height 500ms ease-in-out;

      p {
        @apply my-2 text-oscura;
        font-size: 1rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;

        @media (max-width: 768px) {
          @apply hidden;
        }

        @media (max-width: 1024px) {
          font-size: 14px;
        }

        @media (min-width: 1600px) {
          @apply my-4;
        }
      }

      &__link-container {
        @apply text-center mt-2;

        a {
          @apply flex justify-center items-center text-clara font-semibold;
          font-size: 1rem;

          @media (max-width: 1024px) {
            @apply mt-2;
            font-size: 14px;
          }

          [class^="icon-"],
          [class*=" icon-"] {
            margin-top: 2px;
            &:before {
              font-size: 1.5rem;

              @media (max-width: 1024px) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  &__link-container {
  }
}
