/* --------  MOBILE STYLES ------------ */
@media only screen and (min-width: 200px) and (max-width: 768px) {
  .navigation__bottombar {
    padding: 20px 31px;
  }

  .sidemenu__link {
    font-size: 21px !important;
  }

  .py-4.navigation__topbar {
    padding-top: 13px;
    padding-bottom: 6px;
    margin-top: -1px;
  }

  #quienes-somos .content p {
    font-size: 18px !important;
    line-height: 21px !important;
  }

  .side-menu__bottom-list a.active {
    font-size: 18px !important;
  }

  .side-menu__bottom-list a,
  #quienes-somos .content ul li {
    font-size: 18px !important;
  }

  .side-menu__title {
    font-size: 21px !important;
  }

  #s4-bodyContainer .article-text__head-title {
    font-size: 21px !important;
  }

  #quienes-somos .content h1 {
    font-size: 19px !important;
  }

  #s4-bodyContainer .section.record {
    padding-bottom: 0 !important;
  }

  #s4-bodyContainer .record .container {
    padding: 0 30px;
  }

  .sidemenu__link:after {
    height: 30px;
    width: 30px;
    right: 0;
    font-size: 21px !important;
    top: 11px;
    color: #8d93ac;
  }

  .sidemenu__link {
    font-size: 20px !important;
    padding: 15px 0;
  }

  #s4-bodyContainer .generic-title {
    margin-bottom: 11px;
  }

  #s4-bodyContainer .generic-title {
    line-height: normal !important;
    /* 30px */
  }

  #s4-bodyContainer .generic-button,
  #s4-bodyContainer a.generic-button {
    padding: 14px 30px !important;
    font-size: 18px !important;
  }

  .section.offer .offer__container .offer__tab {
    font-size: 16px;
  }

  .section.record {
    padding-bottom: 0 !important;
  }

  #s4-bodyContainer .ms-rtestate-field p {
    font-size: 18px;
    line-height: 22px;
    font-family: 'FreightSansProMedium' !important;
  }

  .section.offer .container {
    padding-left: 0;
    padding-right: 0;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__top {
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 40px !important;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__container-force {
    padding: 0 !important;
  }

  .footer__logo-box img {
    width: 200px;
  }

  rtestate-field .footer__social-box a {
    margin-right: 11px !important;
  }

  .banner-top {
    min-height: 134px !important;
  }

  /* --- content seciont --- */
  #historia .content p {
    line-height: 21px !important;
  }

  #s4-bodyContainer .ms-rtestate-field p,
  #fideicomiso .description li,
  .custom-list.number-list>li {
    font-size: 18px !important;
  }

  .tabs-container .react-tabs__tab {
    border-radius: 11px !important;
    font-size: 19px !important;
    padding: 17px 36px !important;
    border: none !important;
  }

  .custom-list.number-list>li {
    padding-left: 53px !important;
  }

  .custom-list.number-list>li:before {
    width: 40px !important;
    height: 40px !important;
    font-size: 22px !important;
    padding-top: 8px !important;
    font-family: Arial, Helvetica, sans-serif !important;
  }

  /* --- Conoce mas --- */
  .custom-list.check-list>li {
    font-size: 18px !important;
    font-family: 'FreightSansProMedium' !important;
  }

  .educativa-fideicomiso .bg-oscura {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .educativa-fideicomiso .participants {
    margin-top: 0;
  }

  .educativa-multimedia #s4-bodyContainer .article {
    padding-right: 15px !important;
    padding-left: 18px !important;
  }

  .head-section form select {
    flex: 1 1 auto;
    font-size: 16px !important;
    font-weight: normal !important;
  }

  .educativa-multimedia .head-section form label {
    font-size: 18px !important;
  }

  #sub-bar .container #sub-bar-slider .splide__track .splide__list .splide__slide .slide-content span {
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    font-size: 18px !important;
  }

  #s4-bodyContainer .educativa-multimedia.section,
  #s4-bodyContainer .educativa-marco-legal.section,
  #s4-bodyContainer .educativa-glosario.section {
    padding: 0 0 60px 0 !important;
  }

  #s4-bodyContainer .educativa-glosario.section .article-text__head-title {
    margin-bottom: -17px !important;
  }

  .terms-container .words-container .__react_component_tooltip {
    font-size: 18px !important;
    line-height: 21px !important;
  }

  .documents__filter label {
    font-size: 16px !important;
  }

  #s4-bodyContainer .file__info .file-type-container {
    display: block !important;
  }

  #s4-bodyContainer .file__download-link a {
    font-size: 18px !important;
    width: 100% !important;
    text-align: center !important;
  }

  .documents .documents__filter label {
    font-weight: normal !important;
    font-size: 18px !important;
  }

  .documents__filter__fields .property-container {
    overflow-x: scroll;
  }

  .property-container .filter-property {
    margin-left: 11px;
  }

  .documents__filter__fields {
    overflow-y: auto;
  }

  .documents__filter input,
  .documents__filter select {
    font-size: 18px !important;
  }

  .documents__list__section h4 {
    font-size: 21px !important;
  }

  .terms-container .words-container .words li {
    font-size: 17px !important;
    color: #00AEF0;
    font-family: 'FreightSansProMedium' !important;
  }

  .terms-container .words-container .letter h1 {
    font-size: 30px !important;
  }

  #s4-bodyContainer .educativa-marco-legal .article {
    padding-right: 15px;
    padding-left: 15px;
  }

  #sub-bar .container #sub-bar-slider .splide__track .splide__list .splide__slide .slide-content img {
    object-fit: cover;
    width: 39px !important;
    height: 38px !important;
  }

  /* --- Sala de prensa --- */

  .project.project--noticias .project__select {
    font-size: 18px !important;
  }

  .project.project--noticias .project__filter-text {
    font-size: 18px !important;
  }

  .project.project--noticias .ms-rtestate-field p {
    font-size: 18px !important;
    line-height: 21px !important;
  }

  .noticias__text .noticias__title {
    font-family: 'FreightSansProBold';
    font-size: 21px;
    line-height: normal !important;
    margin-bottom: 24px;
  }

  #s4-bodyContainer .ms-rtestate-field p {
    line-height: 21px !important;
  }

  .project.project--noticias .noticias {
    padding: 63px 0px !important;
  }

  .noticias__date h4 {
    font-size: 16px !important;
  }

  /* --- Transparencia --- */

  #s4-bodyContainer .article-text .toggle-list--fide span {
    font-size: 18px !important;
  }

  /* --- Contacto --- */
  .contact-title {
    font-size: 21px !important;
  }

  .contacto__info:last-child {
    margin-bottom: 24px !important;
  }

  .contacto__text__email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .offer__action-tab {
    font-size: 18px !important;
  }

  .icon-calendar:before {
    font-size: 22px;
    padding-right: 8px;
  }

  /*
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
*/
  #quienes-somos .content ul li:before {
    margin-top: 4px !important;
  }

  .content ul {
    padding-top: 8px;
  }

  .content {
    font-family: 'FreightSansProMedium' !important;
  }

  .navigation__topbar {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .content .article-text__document-title {
    font-size: 19px !important;
    line-height: normal !important;
  }

  .article.section .ms-rtestate-field h3 {
    line-height: 1.1;
  }

  .article-text__help .article-text__help-text {
    text-align: center;
    font-size: 15px !important;
  }

  .article-text__help .article-text__help-text h4 {
    font-size: 18px !important;
  }

  #equipo-ejecutivo .article-text__help-text a {
    font-size: 18px;
    padding-top: 5px;
    display: block;
  }

  .project-list__city {
    font-size: 18px;
    color: rgb(119, 119, 119) !important;
  }

  .project-list__name {
    font-size: 21px !important;
  }

  .project__select {
    font-size: 18px !important;
  }

  .project__filter-text {
    font-size: 19px !important;
  }

  .article-text__parra {
    line-height: normal !important;
    font-size: 18px !important;
    font-family: 'FreightSansProMedium' !important;
  }

  .project .container {
    padding: 0 30px !important;
  }

  #s4-bodyContainer .article-text__parra ol li,
  #s4-bodyContainer .article-text__parra ul li {
    font-size: 18px !important;
    margin-bottom: 8px;
  }

  .project-head .container .head-section>div {
    width: 100%;
    margin-top: -30px;
  }

  .project .project-right h3 {
    font-size: 20px !important;
  }

  .project .project-right h4 {
    font-size: 18px !important;
    line-height: normal !important;
  }

  .project .project-right h5 {
    font-size: 18px !important;
  }

  #s4-bodyContainer .article-text__title {
    font-size: 19px !important;
  }

  #s4-bodyContainer .ms-rtestate-field p {
    line-height: 24px !important;
  }

  .hover-profile-card__photo {
    object-fit: cover;
    width: 155px !important;
    height: 155px !important;
  }

  .hover-profile-card:hover .hover-profile-card__photo {
    width: 104px !important;
    height: 104px !important;
  }

  .hover-profile-card__name {
    font-size: 21px !important;
  }

  .hover-profile-card {
    height: 330px !important;
  }

  .documents__filter__fields .property-container {
    display: block !important;
  }

  .documents__filter__fields .property-container .filter-property {
    display: flex;
    align-items: center;
    --tw-text-opacity: 1;
    color: rgba(38, 78, 114, var(--tw-text-opacity));
    font-size: 21px;
    height: 20px;
    margin-top: 20px;
  }

  .documents__filter__fields .property-container {
    overflow-x: initial !important;
  }

  #s4-bodyContainer .form-control .global-input {
    font-size: 18px !important;
    line-height: 36px !important;
    padding: 6px 12px !important;
  }

  .contact-info .mr-6 {
    margin-right: 0;
  }

  .map__text {
    margin-bottom: 30px;
  }

  #s4-bodyContainer .form-control .global-textarea {
    font-size: 18px !important;
  }

  .contacto__text__email {
    text-align: left;
    width: 80%;
    display: block;
    margin-bottom: -18px;
  }

  /* .noticias.noticias--open {
      padding: 63px 0 !important;
    } */
  .splide__arrows .splide__arrow img {
    width: 17px;
  }

  .head-section__back {
    margin-bottom: 15px;
  }

  .social-share__box {
    margin-right: 8px;
  }

  .toggle-list__item--faq .arrow-icon {
    margin-right: -18px;
  }

  .educativa-fideicomiso .intro-container .list-container .general-benefits {
    padding: 27px !important;
  }

  .flex.items-center.navigation__actions.flex-1 {
    flex: 0 !important;
  }


}

/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------- Desktop Screens ------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/

@media (min-width: 992px) {
  .container {
    max-width: 1140px;
  }

  .embeddedServiceHelpButton .helpButton .uiButton {
    box-shadow: none !important;
  }

  .navigation__search {
    margin-right: 22px;
  }

  .navigation__logo {
    width: 230px !important;
  }

  #s4-bodyContainer .slider-home__title {
    line-height: 41px !important;
    font-size: 3.5em !important;
  }

  #s4-bodyContainer .ms-rtestate-field p {
    font-size: 1.1rem !important;
  }

  #s4-bodyContainer .welcome-section__text-block h2 {
    font-size: 38px !important;
    line-height: 42px !important;
  }

  #s4-bodyContainer .welcome-section__text-block p {
    font-size: 18px !important;
    line-height: 1.3em !important;
  }

  #s4-bodyContainer .generic-title--record {
    font-size: 38px !important;
    line-height: 38px !important;
  }

  #s4-bodyContainer .generic-title--record span {
    font-size: 24px !important;
    line-height: 38px !important;
  }

  #s4-bodyContainer .record__number {
    font-size: 44px !important;
  }

  #s4-bodyContainer .generic-title {
    font-size: 35px !important;
    line-height: 38px !important;
  }

  #s4-bodyContainer .generic-title span {
    font-size: 24px !important;
  }

  #s4-bodyContainer .ms-rtestate-field p {
    font-size: 16px !important;
  }

  #s4-bodyContainer .generic-button,
  #s4-bodyContainer a.generic-button,
  #s4-bodyContainer buttton.generic-button {
    font-size: 18px !important;
  }

  .offer__tab.active {
    font-size: 18px !important;
  }

  .offer__tab {
    font-size: 18px !important;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__text {
    font-size: 15px !important;
    color: #ffffff !important;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__text--phone {
    margin-top: 24px;
    font-size: 15px !important;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__link {
    font-size: 15px !important;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__disclaimer {
    font-size: 15px !important;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__closing-links a,
  #s4-bodyContainer .ms-rtestate-field .footer__closing-links span {
    font-size: 15px !important;
  }

  .navigation__action-link {
    font-size: 18px !important;
  }

  .section.offer .md\:gap-6 {
    gap: 4.5rem;
  }

  #historia .content p,
  #s4-bodyContainer .ms-rtestate-field p,
  #quienes-somos .content p {
    font-size: 18px !important;
    line-height: 21px !important;
  }

  #s4-bodyContainer .article-text__head-title,
  #quienes-somos .content h1 {
    font-size: 40px !important;
    margin-bottom: 15px !important;
  }

  #quienes-somos .content ul li {
    font-size: 16px;
  }

  .side-menu__bottom-list a.active,
  .side-menu__bottom-list a {
    font-size: 18px !important;
  }

  #s4-bodyContainer .article-text__document-title {
    font-size: 20px !important;
  }

  .board-container__member .name {
    font-size: 18px !important;
  }

  #fideicomiso .description div,
  #fideicomiso .description li,
  #fideicomiso .description p,
  #fideicomiso .nota p,
  .custom-list.number-list>li {
    font-size: 16px !important;
  }

  .tabs-container .react-tabs__tab {
    font-size: 19px !important;
  }

  .project-list__name {
    font-size: 20px !important;
    line-height: 30px;
  }

  .banner-top__title {
    font-size: 40px !important;
  }

  #s4-bodyContainer .section,
  #s4-bodyContainer div.section {
    padding: 50px 0;
  }

  /* .offer__tabs-list {
    max-height: 440px;
    overflow-y: scroll;
    height: 440px;
  } */
  .adressmodal__info {
    font-size: 16px !important;
  }

  /* -------- FORM BOX -------- */
  .form-box {
    margin: 18px;
  }

  /* Search */
  .form-box form {
    border-bottom: solid 1px #f0f0f0;
    padding: 0 16px;
  }

  .form-box input {
    border: none;
  }

  .adressmodal__close {
    font-size: 15px;
    position: absolute;
    top: -48px;
    right: -12px;
    color: white;

    @media (min-width: 680px) {
      top: 23px !important;
      right: 14px !important;
      color: #ccc;
    }
  }

  #s4-bodyContainer .form-clear {
    width: 35px;
    height: 35px;
    position: absolute;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 10px;
    top: 12px;
  }

  .search-bottom__link-question img {
    bottom: -30px;
  }

  @media (min-width: 992px) {
    .search-bottom__link-question img {
      bottom: 0;
    }
  }

  /* End Search */

  #s4-bodyContainer .form-control__icon {
    top: 14px;
    left: 14px;
    font-size: 33px;
  }

  #s4-bodyContainer .form-control .generic-input {
    width: 100%;
    border-radius: 12px;
    height: 60px;
    padding-left: 60px;
    font-family: 'FreightSansProMedium';
    font-size: 19px;
    border: none;
  }
}

@media (max-width: 680px) {
  .documents__filter__fields {
    display: block;
  }

  .documents__filter__fields .field-container {
    margin-top: 20px;
  }

  .global {
    width: 100%;
  }
}

a.adressmodal__info-text {
  color: #fff !important;
}

body .adressmodal__info,
body .adressmodal__title {
  margin-bottom: 17px;
}

.icon-calendar:before {
  font-size: 22px;
  padding-right: 8px;
}

#adressModal span::before {
  position: absolute;
  left: 0;
}

#adressModal h2.adressmodal__info {
  position: relative;
  padding-left: 30px;
}

.text-white.feather.icon-map-pin,
.text-white.feather.icon-phone,
.text-white.feather.icon-mail {
  font-size: 19px;
  margin-right: 3px !important;
}

/* ----------------------------- SEARCH RESPONSIVE -----------------------------------*/

@media (min-width: 280px) {
  #s4-bodyContainer .form-clear {
    top: 13px;
  }

  .adressmodal {
    min-height: 180px;
  }

  #s4-bodyContainer .form-control .generic-input {
    border: none;
    font-size: 17px;
    padding-left: 72px;
  }

  /*
  .search-bottom__question {
    background: pink;
  }
  */
  .adressmodal__close {
    font-size: 14px !important;
    top: -26px;
    color: #fff !important;
    right: -9px;
  }

  .search-bottom {
    padding: 12px 25px;
    justify-content: space-between;
  }

  .adressmodal--search {
    border-radius: 10px;
  }

  #s4-bodyContainer .form-control__icon {
    top: 14px;
    left: 23px;
  }
}

/* 
 @media (min-width: 681px) and (max-width: 1279px) {
  .search-bottom__question {
     background: red; 
  }
 }

 @media (min-width: 1280px){
  .search-bottom__question {
   background: green !important; 
  }
 }
*/

.terms-container .words-container .words li {
  color: #00AEF0 !important;
  font-family: 'FreightSansProMedium' !important;
}

@media (min-width: 681px) and (max-width: 1800px) {
  .py-4.navigation__topbar {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media (min-width: 992px) {
  #closeSearch span {
    font-size: 18px;
    color: #fff;
    top: -60px;
    position: absolute;
    right: -10px;
  }
}

@media (min-width: 1200px) {
  .offer__tabs-list {
    max-height: 440px;
    overflow-y: scroll;
    height: 440px;
  }
}