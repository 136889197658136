#terminos-uso {
  .article-text {
    @apply my-16;
  }
  .content {
    @apply my-6 p-0 text-oscura;
    font-size: 22px;
    font-family: "FreightSansProMedium";

    h1 {
      color: #00aef0;
      font-family: FreightSansProBold;
      font-size: 25px;

      @media (min-width: 768px) {
        font-size: 30px;
        margin-bottom: 10px;
      }
    }

    p {
      color: #264e72;
      font-family: "FreightSansProMedium";
      font-size: 18px;
      line-height: 38px;

      @media (min-width: 768px) {
        font-size: 22px;
      }

      ul {
        padding-left: 24px;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          height: 10px;
          width: 10px;
          @apply bg-clara;
          border-radius: 50%;
          left: 0;
          top: 14px;
        }
      }
    }
  }
}
