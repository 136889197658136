.horizontal-project-card {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px dashed #626465;

  &:last-of-type {
    margin-bottom: 0;
  }

  .info {
    flex: 1;
  }

  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-right: 46px;
    border-radius: 10px;
  }

  .icon-chevron-right {
    font-size: 48px;
    color: #a5a5a5;
  }
}
