.article,
div.article {
  padding-right: 40px;
  padding-left: 40px;
}

.article-text {
  padding-top: 28px;

  @media (min-width: 1280px) {
    padding-top: 0;
  }

  &__image {
    margin: 24px 0;
  }

  &__head-title {
    color: #00aef0;
    font-family: FreightSansProBold;
    font-size: 26px;

    @media (min-width: 768px) {
      font-size: 40px;
    }
  }

  &__head-sub-title {
    @apply text-oscura;
    font-family: FreightSansProBold;
    font-size: 21px;

    @media (min-width: 768px) {
      font-size: 35px;
    }
  }

  &__title {
    color: #00aef0;
    font-family: FreightSansProBold;
    font-size: 25px;

    @media (min-width: 768px) {
      font-size: 33px;
    }
  }

  &__parra {
    color: #1b3a57;
    font-family: "FreightSansProMedium";
    font-size: 18px;
    margin-bottom: 49px;
    line-height: 38px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list {
  }

  &__list-item {
    &:before {
      @apply mr-4 text-clara;
      content: "\2022";
      font-size: 1.5rem;
    }
  }

  &__document {
    margin-top: 46px;
  }

  &__document-info {
    margin-top: 12px;

    @media (min-width: 1280px) {
      margin-top: 0;
    }
  }

  &__document-title {
    color: #264e72;
    font-family: FreightSansProBold;
    font-size: 18px;
    margin-bottom: 6px;

    @media (min-width: 1280px) {
      font-size: 25px;
    }
  }

  &__document-text {
    color: #1b3a57;
    font-family: "FreightSansProMedium";
    opacity: 0.53;
    font-size: 18px;
    margin-bottom: 6px;

    @media (min-width: 1280px) {
      font-size: 19px;
    }
  }

  &__document-img {
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px,
      rgb(0 0 0 / 6%) 0px 1px 2px 0px;

    @media (min-width: 768px) {
      width: 167px;
      height: 207px;
      object-fit: cover;
      margin-right: 24px;
    }
  }

  &__organigrama {
  }

  &__organigrama-filial {
    padding: 12px;
    margin-bottom: 40px;
    background-color: #f1f3f6;
    border-radius: 10px;
    position: relative;

    &--small {
      width: 85%;
      margin-left: auto;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        height: 4px;
        width: 35px;
        background-color: #e6e6e6;
        right: 100%;
        top: 50%;
      }

      &:after {
        content: "";
        position: absolute;
        height: 150%;
        width: 4px;
        background-color: #e6e6e6;
        left: -35px;
        bottom: 50%;
        border-radius: 5px;
        z-index: -1;
      }
    }
  }

  &__organigrama-link {
    display: block;
    text-align: right;
    bottom: 12px;
    color: #159cd1;
    position: absolute;
    font-size: 18px;
    right: 12px;

    @media (min-width: 1280px) {
      display: inline-block;
      text-align: right;
      color: #159cd1;
      font-size: 27px;
      right: 15px;
      bottom: 15px;
    }
  }

  &__image-box {
    background-color: #fff;
    margin-bottom: 12px;

    @media (min-width: 768px) {
      max-width: 50%;
      flex: 0 0 50%;
      margin-bottom: 0;
    }
  }

  &__organigrama-image {
    width: 399px;
    height: 139px;
    object-fit: cover;
  }

  &__organigrama-text {
    @media (min-width: 768px) {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 24px;
    }
  }

  &__organigrama-desc {
    font-family: "FreightSansProMedium";
    color: #1b3a57;
    font-size: 18px;
  }

  &__organigrama-image {
    border-radius: 10px;
  }

  &__document-download {
    color: #00aef0;
    font-family: "FreightSansProMedium";
    font-size: 18px;
    margin-bottom: 6px;
    align-items: center;

    &:hover {
      color: #264e72;
    }

    @media (min-width: 1280px) {
      font-size: 19px;
    }

    span {
      margin-right: 6px;
      font-size: 23px;
    }
  }
}

.cta {
  background-color: #f8ebda;
  border-radius: 15px;
  padding: 60px 30px;
  background-image: url(../../assets/img/article/cta-image.png);
  min-height: 500px;
  background-size: 100%;
  background-repeat: no-repeat;

  &__title {
    color: #264e72;
    font-size: 30px;
    font-family: FreightSansProBold;
    text-align: center;
    margin-bottom: 22px;
    line-height: 28px;
  }
}
