#memberModal {
  border-radius: 12px;

  #closeMember {
    @apply bg-transparent;
    font-size: 14px!important;
    top: 12px !important;
    color: #fff!important;
    right: auto !important;
    color: #afafaf !important;
    opacity: 0.8 !important;
    &:active,
    &:hover,
    &:focus {
      opacity: 1 !important;
      @apply bg-transparent;
    }
  }

  
}

.member-modal {
  display: block;
  padding: 25px;

  @media (min-width:680px) {
    display: flex;
    padding: 46px;
  }

  &__content-wrapper {
    /* margin-top: 50px; */
    margin-bottom: 20px;
    text-align: center;

    @media (min-width:680px) {
      text-align: left;
    }
  }

  &__image {
    max-width: 100%;
    flex: 0;
    margin-right: 0;

    @media (min-width:680px) {
      max-width: 30%;
      flex: 0 0 30%;
      margin-right: 60px;
    }

    img {
      border: unset !important;
      border-radius: 15px;
    }
  }

  &__name {
    font-size: 21px;
    margin-top: 10px;
    font-family: "FreightSansProBold";
    color: #00aef0;

    @media (min-width: 681px) and (max-width:959) {
      margin-top: 0;
      font-size: 25px;
    }

    @media (min-width:960px) {
      margin-top: 0;
      font-size: 45px;
    }

  }

  &__position {
    color: #264e72;
    font-family: "FreightSansProBold";
    font-size: 21px;

    @media (min-width: 681px) and (max-width:959) {
      margin-top: 0;
      font-size: 18px;
    }

    @media (min-width:960px) {
      margin-top: 0;
      font-size: 26px;
    }
  }

  &__text {

    overflow-y: auto;

    @media (min-width:768px) {
      height: 400px;
    }

    p {
      font-family: "FreightSansProMedium";
      font-size: 19px;
      color: #264e72;
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    button {
      margin-top: 16px;
    }
  }
}

.overlay-member {
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(38, 78, 114, 0.8);
}

html {
  overflow: auto !important;
  padding-right: unset !important;
}

@media (min-width: 680px) {
  .member-modal__content-wrapper.hide-on-mobile {
    display: none;
  }
}