#s4-bodyContainer {
  .article-text {
    &:not(:first-child) {
      margin-top: 28px;
    }

    &__parra {
      @apply whitespace-pre-wrap;

      ul,
      ol {
        li {
          font-size: 22px;
        }
      }
    }
  }

  .project-detail {
    &__project-info {
      @apply grid grid-cols-1 gap-14 mt-8;

      @media (min-width: 1024px) {
        @apply grid-cols-3 mt-16;
      }
    }
    &__cover {
      img {
        border-radius: 10px;
      }
    }
    &__article-container {
      @media (min-width: 1024px) {
        @apply col-span-2;
      }

      .article-text {
        padding-top: 0;

        .article-text__parra {
          @apply whitespace-pre-wrap;
        }
      }
    }
    &__contact-info {
      @apply col-span-1;

      .project-right {
        @apply h-auto;

        h3 {
          @media (min-width: 1280px) {
            font-size: 28px;
          }
        }
      }
    }
  }
}
