.offer {
  padding-left: 40px;
  padding-right: 40px;

  .generic-title {
    margin-bottom: 40px;

    @media (min-width: 1280px) {
      font-size: 54px;
    }

    span {
      @apply text-oscura;
      @media (min-width: 1280px) {
        font-size: 33px;
      }
    }
  }

  &__container {
    @media (min-width: 1280px) {
      @apply gap-12;
    }
  }

  &__action-tab {
    @apply text-oscura text-left;
  }

  &__tabs-list {
    /*
    max-height: 500px;
    overflow-y: scroll;
    height: 500px; */
  }

  &__box-mobile {
    @apply max-h-0 overflow-hidden;

    transition: max-height 0.5s ease-in-out;

    @media (min-width: 1200px) {
      @apply hidden;
    }

    &.active {
      max-height: 1000px;
    }

    .button-container {
      @apply px-12;
      .generic-button {
      }
    }
  }

  &__box {
    @media (max-width: 1024px) {
      @apply hidden;
    }
    @media (min-width: 1280px) {
      @apply gap-12;
    }
  }

  &__tab {
    @apply text-oscurisima;
    background-color: #f1f3f6;
    font-family: 'FreightSansProMedium';
    padding: 22px 26px;
    border-radius: 10px;
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
    transition: all ease-in-out 0.3s;
    cursor: pointer;

    @media (min-width: 1280px) {
      font-size: 20px;
    }

    &.active {
      background-color: #00aef0;
      color: #fff;
      margin-bottom: 16px;

      @media (min-width: 1280px) {
        margin-bottom: 16px;
      }
    }

    &.active button {
      color: #fff !important;
    }

    &:hover {
      background-color: #00aef0;
      color: #fff !important;
      & button {
        color: #fff !important;
      }
    }
  }

  &__tab-content {
    padding: 26px 0;
  }

  &__text-box {
    .generic-button {
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }
  }

  &__text {
    font-family: 'FreightSansProMedium';
    font-size: 17px;
    font-weight: 500;
    color: #1b3a57;
    line-height: 24px;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 29px;
    }
  }

  &__image {
    width: calc(100% - 10px);
    border-radius: 30px;
    box-shadow: 10px 10px 0px #f4f4f4;
  }

  &__image-box {
    margin-bottom: 40px;
  }
}
