#fideicomiso {
  .article-text {
    & > section {
      &:not(:first-child) {
        @apply mt-12;
      }
    }

    .info-text {
      .side-text-container {
        font-size: 22px;
      }
    }
  }

  h3 {
    @apply mb-6;
  }

  .description {
    p,
    li,
    div {
      font-family: "FreightSansProMedium";
      line-height: normal;

      @media (min-width: 1024px) {
        font-size: 18px !important;
        line-height: 21px !important;
      }
    }
  }

  .nota {
    p {
      @media (min-width: 1024px) {
        font-size: 18px !important;
        line-height: 21px !important;
      }
    }
  }

  .banner-container {
    @apply flex justify-between gap-8 mt-12;

    @media (max-width: 1023px) {
      @apply flex-wrap;
    }

    .banner {
      @apply relative;
      &__info {
        @apply absolute h-full flex flex-wrap justify-start content-center gap-4 pr-4;
        width: 55%;
        top: 0;
        right: 0;

        h3 {
          @apply text-oscura font-bold;
          font-size: 26px;

          @media (max-width: 640px) {
            font-size: 18px;
          }
          @media (min-width: 1024px) {
            font-size: 22px;
          }
          @media (min-width: 1280px) {
            font-size: 26px;
          }
        }

        .btn-primary {
          @apply inline-block bg-clara text-blanco px-10 py-3;
          border-radius: 14px;

          @media (max-width: 640px) {
            @apply hidden;
          }
        }

        #fideicomiso ul li {
          color: #244e6e;
        }
      }
    }
  }

  .bonos {
    h3 {
      @apply mb-6;
    }

    ul {
      @apply flex flex-wrap gap-5;
      li {
        @apply p-3;
        flex: 0 1 260px;

        border: 2px solid hsla(0, 0%, 91.8%, 0.8901960784313725);
        border-radius: 10px;

        img {
          @apply inline-block mr-6;
        }

        a {
          @apply text-clara;
          font-size: 21px;

          &:visited,
          &:active,
          &:focus {
            @apply text-clara;
            text-decoration: none;
          }
        }
      }
    }
  }
}
