#s4-bodyContainer {
  .article {
    padding-right: 40px;
    padding-left: 40px;

    @media (max-width: 700px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    &--detail {
      padding: 0;
      margin-top: 24px;

      @media (min-width: 1280px) {
        margin: 60px 0 30px;
      }
    }
  }

  .article-box {
    background-color: #ffffff;
    box-shadow: 0px 0px 46px #00000017;
    padding: 20px;
    margin-top: 20px;

    label {
      margin-bottom: 10px;
    }

    @media (min-width: 1280px) {
      select {
        margin-right: 6px;
        margin-bottom: 0px;
      }
    }
  }

  .article-text {
    padding-top: 28px;

    @media (min-width: 1280px) {
      padding-top: 0;
    }

    &-detail {
      &:not(:first-child) {
        @apply mt-8;
      }
    }

    .toggle-list--fide {
      span {
        font-family: 'FreightSansProMedium';
        font-size: 24px;
      }

      .content {
        p {
          font-family: 'FreightSansProMedium';
          font-size: 22px;
        }

        .transparency-bg {
          background-color: #f1f3f6;
          padding: 36px 18px;
          border-radius: 18px;

          @media (min-width: 1280px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .transparency-download {
            color: #fff;
            background-color: #00aef0;
            font-family: 'FreightSansProMedium';
            border-radius: 10px;
            padding: 10px 28px;

            .text {
              margin-left: 10px;
              font-size: 18px;
            }
          }
        }

        .transparency-info {
          @media (min-width: 1280px) {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            padding-right: 40px;
            align-items: center;
          }

          .title {
            @apply text-oscurisima;
            font-family: 'FreightSansProMedium';
            font-size: 22px;
            flex: 0 0 100%;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 25px;

            @media (min-width: 1280px) {
              @apply text-oscurisima;
              font-family: 'FreightSansProMedium';
              font-size: 22px;
              flex: 0 0 100%;
              margin-bottom: 0;
              line-height: normal;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          img {
            margin-right: 40px;
            margin-bottom: 12px;

            @media (min-width: 1280px) {
              margin-right: 40px;
              margin-bottom: 0;
            }
          }

          .subtitle {
            color: #264e72;
            margin-bottom: 12px;
            line-height: 22px;
            opacity: 0.6;

            @media (min-width: 1280px) {
              font-size: 19px;
              margin-bottom: 0;
              line-height: auto;
            }
          }
        }
      }
    }

    &__video-image {
      margin: 24px 0;
    }

    &__video-image-row {
      display: block;

      @media (min-width: 1280px) {
        display: flex;
        align-items: center;
        margin-top: 24px;
      }
    }

    &__bono {
      @media (min-width: 1280px) {
        display: flex;
        justify-content: space-between;
      }
    }

    &__bono-link {
      border: 2px solid #eaeaeae3;
      border-radius: 10px;
      display: flex;
      align-items: center;
      font-family: 'FreightSansProMedium';
      @apply text-clara;
      font-size: 22px;
      padding: 10px 23px;
      align-items: center;
      max-width: 100%;
      margin: 12px 0;

      @media (min-width: 1280px) {
        border: 2px solid #eaeaeae3;
        border-radius: 10px;
        display: inline-flex;
        align-items: center;
        font-family: 'FreightSansProMedium';
        @apply text-clara;
        font-size: 22px;
        padding: 10px 23px;
        align-items: center;
        max-width: 261px;
        margin: 0;
      }

      img {
        margin-right: 24px;
      }
    }

    &__video-image-box {
      position: relative;
      margin-right: 4%;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .circle {
          width: 100px;
          height: 100px;
          background: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            width: 36px;
            height: auto;
            margin: 0;
          }
        }
      }

      img {
        width: 385px;
        height: 227px;
        object-fit: cover;
      }
    }

    &__video-image-text {
      margin: 16px 0;

      @media (min-width: 1280px) {
        width: 48%;
      }
    }

    &__video-image-parragraf {
      font-family: 'FreightSansProMedium';
      font-size: 20px;
      color: #264e72;
    }

    &__disclaimer {
      font-family: 'FreightSansProMedium';
      font-size: 22px;
      margin: 44px 0;
    }

    &__help {
      display: flex;
      margin-top: 36px;

      @media (max-width: 649px) {
        @apply flex-wrap justify-center;
        text-align: center;
      }
    }

    &__readmore {
      color: #00aef0;
      font-size: 22px;
      font-family: 'FreightSansProMedium';
    }

    &__help-icon {
      font-size: 64px;
      color: #00aef0;
      margin-right: 14px;

      @media (max-width: 639px) {
        @apply text-center;
        flex: 1 0 100%;
      }
    }

    &__help-text {
      h4 {
        @apply text-oscurisima;
        font-family: 'FreightSansProMedium';
        font-size: 24px;
        margin-bottom: -6px;

        @media (max-width: 639px) {
          font-size: 19px;
        }
      }

      a {
        @apply text-oscura;
        font-family: 'FreightSansProMedium';

        @media (max-width: 639px) {
          font-size: 17px;
        }
      }
    }

    &__image {
      margin: 31px 0;
    }

    &__head-title {
      color: #00aef0;
      font-family: FreightSansProBold;
      font-size: 26px;
      margin-bottom: 12px;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 40px;
        margin-bottom: 15px;

        &:not(.text-center) {
          text-align: left;
        }
      }

      &.text-blanco {
        color: #ffffff;
      }

      &--blue {
        @apply text-oscurisima;
      }

      &--center {
        text-align: center;
        margin-bottom: 24px;

        @media (min-width: 1280px) {
          text-align: left;
        }
      }

      &--equipo {
        margin-bottom: -16px;
      }
    }

    &__head-sub-title {
      @apply text-oscura;
      font-family: FreightSansProBold;
      font-size: 21px;

      @media (min-width: 768px) {
        font-size: 35px;
      }
    }

    &__title {
      color: #00aef0;
      font-family: FreightSansProBold;
      font-size: 25px;

      @media (min-width: 768px) {
        font-size: 30px;
        margin-bottom: 10px;
      }

      &--project {
        margin-bottom: 31px;
      }
    }

    &__project-house {
      margin: 24px 0;
      border-bottom: 1px dashed #626465;
      padding-bottom: 24px;

      @media (min-width: 1280px) {
        padding: 0;
        display: flex;
        align-items: center;
        padding-bottom: 24px;
        margin-bottom: 24px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .info {
        flex: 1;
      }

      img {
        width: 100%;
        object-fit: cover;
        margin: 0;

        @media (min-width: 1280px) {
          width: 150px;
          height: 150px;
          object-fit: cover;
          margin-right: 46px;
          border-radius: 10px;
        }
      }

      .icon-chevron-right {
        display: none;

        @media (min-width: 1280px) {
          font-size: 48px;
          color: #a5a5a5;
          display: inline-flex;
        }
      }
    }

    &__parra {
      color: #264e72;
      font-family: 'FreightSansProMedium';
      font-size: 18px;
      margin-bottom: 49px;
      line-height: 38px;

      @media (min-width: 768px) {
        font-size: 22px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--list {
        padding-left: 24px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          height: 10px;
          width: 10px;
          @apply bg-clara;
          border-radius: 50%;
          left: 0;
          top: 14px;
        }
      }

      &__list-small {
        padding-left: 24px;
        position: relative;

        li {}

        &:after {
          content: '';
          position: absolute;
          height: 10px;
          width: 10px;
          @apply bg-clara;
          border-radius: 50%;
          left: 0;
          top: 14px;
        }
      }

      &--list {
        padding-left: 24px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          height: 10px;
          width: 10px;
          @apply bg-clara;
          border-radius: 50%;
          left: 0;
          top: 14px;
        }
      }
    }

    &__list-small {
      color: #264e72;
      font-family: 'FreightSansProMedium';
      font-size: 18px;
      margin-bottom: 49px;
      line-height: 38px;

      @media (min-width: 768px) {
        font-size: 22px;
      }

      li {
        margin-left: 12px;

        &:before {
          @apply mr-4 text-clara;
          content: '\2022';
          font-size: 1.5rem;
        }
      }
    }

    &__list-item {}

    &__document {
      margin-top: 46px;
    }

    &__document-info {
      margin-top: 12px;

      @media (min-width: 1280px) {
        margin-top: 0;
      }
    }

    &__document-title {
      color: #264e72;
      font-family: FreightSansProBold;
      font-size: 25px;
      margin-bottom: 6px;

      @media (min-width: 1280px) {
        font-size: 25px;
      }
    }

    &__document-text {
      color: #264e72;
      font-family: 'FreightSansProMedium';
      font-size: 17px;
      margin-bottom: 6px;

      @media (min-width: 1280px) {
        font-size: 19px;
      }
    }

    &__document-img {
      box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;

      @media (min-width: 768px) {
        width: 167px;
        height: 207px;
        object-fit: cover;
        margin-right: 24px;
      }
    }

    &__organigrama {}

    &__organigrama-filial {
      padding: 20px;
      margin-bottom: 40px;
      background-color: #f1f3f6;
      border-radius: 10px;
      position: relative;
      transition: all ease-out 0.5s;
      cursor: pointer;



      &:first-of-type {
        background-color: #264e72;

        p {
          color: #fff !important;
        }
      }

      &--small {
        width: 85%;
        margin-left: auto;
        position: relative;

        &:hover {
          background-color: #264e72;

          p {
            color: #fff !important;
          }
        }

        &:before {
          content: '';
          position: absolute;
          height: 4px;
          width: 35px;
          background-color: #e6e6e6;
          right: 100%;
          top: 50%;
        }

        &:after {
          content: '';
          position: absolute;
          height: 150%;
          width: 4px;
          background-color: #e6e6e6;
          left: -35px;
          bottom: 50%;
          border-radius: 5px;
          z-index: -1;
        }
      }

      .article-text__image-box {
        flex: 1 1 100%;
      }
    }

    &__organigrama-link {
      display: block;
      text-align: right;
      bottom: 12px;
      color: #159cd1;
      position: absolute;
      font-size: 18px;
      right: 12px;

      @media (min-width: 1280px) {
        display: inline-block;
        text-align: right;
        color: #159cd1;
        font-size: 27px;
        right: 15px;
        bottom: 15px;
      }
    }

    &__image-box {
      background-color: #fff;
      margin-bottom: 12px;
      border-radius: 10px;
      height: 139px;

      @media (min-width: 768px) {
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 0;
      }
    }

    &__organigrama-image {
      /* width: 399px;
      height: 139px; */
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &__organigrama-text {
      @media (min-width: 768px) {
        max-width: 50%;
        flex: 0 0 45%;
        padding: 0 0 0 24px;
      }
    }

    &__organigrama-desc {
      font-family: 'FreightSansProMedium';
      color: #264e72;
      font-size: 18px;
    }

    &__organigrama-image {
      border-radius: 10px;
    }

    &__document-download {
      @apply flex items-center mt-6;
      color: #00aef0;
      font-family: 'FreightSansProMedium';
      font-size: 18px;
      margin-bottom: 6px;
      align-items: center;

      &:hover {
        color: #264e72;
      }

      @media (min-width: 1280px) {
        font-size: 19px;
      }

      span {
        margin-right: 6px;
        font-size: 23px;
      }
    }

    &__image-text {
      img {
        width: 100%;
        margin: 24px;
        margin: 24px 0;

        @media (min-width: 1280px) {
          margin-bottom: 0;
          margin-right: 2.5rem;
          margin-top: 0;
        }
      }

      margin-bottom: 49px;
    }
  }

  .cta {
    background-color: #f8ebda;
    border-radius: 15px;
    padding: 60px 30px;
    background-image: url(../../assets/img/article/cta-image.png);
    min-height: 500px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;

    &__title {
      @apply text-clara;
      font-size: 30px;
      font-family: FreightSansProBold;
      text-align: center;
      margin-bottom: 22px;
      line-height: 28px;

      @media (min-width: 1280px) {
        flex: 0 0 100%;
        text-align: left;
        font-size: 36px;

        span {
          font-size: 26px;
        }
      }
    }

    @media (min-width: 1280px) {
      min-height: 200px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: left 80%;
      justify-content: flex-end;

      .content-box {
        width: 60%;
        margin-left: 40%;
      }

      .cta-button {
        flex: 0 0 100%;
        margin: 0;
        text-align: center;
      }
    }
  }

  .member-modal {
    display: flex;
    padding: 46px;

    &__image {
      max-width: 35%;
      flex: 0 0 40%;
      margin-right: 60px;
    }

    &__name {
      font-family: 'FreightSansProBold';
      color: #00aef0;
      font-size: 45px;
    }

    &__position {
      color: #264e72;
      font-family: 'FreightSansProBold';
      font-size: 26px;
    }

    &__text {
      height: 521px;
      overflow-y: auto;

      p {
        font-family: 'FreightSansProMedium';
        font-size: 19px;
        color: #264e72;
        margin-bottom: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      button {
        margin-top: 16px;
      }
    }
  }

  #morePorjectSlide {
    position: relative;

    .splide__arrow {
      background-color: transparent;
      display: none;

      svg {
        height: 2em !important;
        width: 2em !important;
        fill: #00aef0 !important;
      }

      span {
        font-size: 48px;
        color: #707070;
      }

      @media (min-width: 1200px) {
        display: block;
      }
    }

    .splide__arrow {
      height: 2em !important;
      width: 2em !important;
      fill: #00aef0 !important;
    }

    .splide__arrow--prev {
      left: -3rem;
    }

    .splide__arrow--next {
      right: -3rem;
    }
  }

  .tabs-article {
    &__top {
      background-color: #f1f1f1;
      border-radius: 15px;
      padding: 9px;
      overflow: auto;
      white-space: nowrap;
    }

    &__bottom {
      padding: 32px 0;

      @media (min-width: 1280px) {
        padding: 32px;
      }
    }

    &__button {
      background-color: #f1f1f1;
      font-family: 'FreightSansProMedium';
      border-radius: 15px;
      font-size: 21px;
      padding: 19px 30px;
      color: #0c95c9;
      display: inline-block;

      &--active {
        @apply bg-clara;
        color: #fff;
      }
    }
  }

  .beneficios-list {
    &__number {
      @apply bg-clara;
      color: #fff;
      display: inline-flex;
      width: 50px;
      height: 50px;
      font-family: 'FreightSansProMedium';
      border-radius: 50%;
      font-size: 26px;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      line-height: 50px;
      padding-bottom: 3px;

      @media (min-width: 1280px) {
        align-items: unset;
      }
    }

    &__item {
      font-family: 'FreightSansProMedium';
      font-size: 22px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__text {
      flex: 1;

      @media (min-width: 1280px) {
        padding-top: 8px;
      }
    }
  }

  .referencia {
    padding: 29px 0;

    &__item {
      margin-bottom: 12px;

      @media (min-width: 1280px) {
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 24px;
        }
      }
    }

    &__title {
      color: #264e72;
      text-align: center;
      font-family: 'FreightSansProMedium';
      font-size: 24px;
      display: block;
      text-align: center;
      margin-bottom: 8px;

      @media (min-width: 1280px) {
        text-align: left;
        margin-bottom: 8px;
      }
    }

    &__top {
      background-color: #264e72;
      border-radius: 10px 10px 0 0;
      padding: 13px 24px;
      font-size: 18px;

      span.name {
        color: #ffffff;
        font-family: 'FreightSansProBold';

        @media (min-width: 1280px) {
          display: inline-block;
          font-size: 20px;
        }
      }

      span.number {
        color: #ffffffd4;
        font-family: 'FreightSansProBook';
        display: block;

        @media (min-width: 1280px) {
          display: inline-block;
          font-size: 20px;
        }
      }
    }

    &__bottom {
      background-color: #f1f3f6;
      border-radius: 0 0 10px 10px;
      padding: 13px 24px;

      @media (min-width: 1280px) {
        padding: 25px 24px;
      }

      h4 {
        color: #264e72;
        font-size: 20px;
        font-family: 'FreightSansProBold';

        @media (min-width: 1280px) {
          font-size: 22px;
        }
      }

      .tipo {
        margin-top: 8px;
      }

      h5 {
        font-family: 'FreightSansProMedium';
        font-size: 18px;
        color: #264e72;

        span {
          font-family: 'FreightSansProBold';
        }
      }

      h6 {
        font-family: 'FreightSansProMedium';
        font-size: 15px;
        color: #264e72;

        @media (min-width: 1280px) {
          font-size: 16px;
        }
      }

      .btn-primary {
        @apply block px-10 py-3 bg-clara text-blanco;
        border-radius: 14px;
        font-family: 'FreightSansProMedium';
        text-align: center;
        font-size: 19px;
        margin-top: 20px;

        @media (min-width: 1280px) {
          display: inline-block;
          margin-top: 0;
        }
      }
    }
  }

  .documento {
    &__top {
      background-color: #264e72;
      padding: 13px 24px;
      font-size: 18px;

      span {
        color: #ffffff;
        font-family: 'FreightSansProMedium';
        display: block;

        @media (min-width: 1280px) {
          display: inline-block;
          font-size: 20px;
        }
      }
    }

    .btn-primary {
      @apply block px-10 py-3 bg-clara text-blanco;
      border-radius: 14px;
      font-family: 'FreightSansProMedium';
      text-align: center;
      font-size: 19px;
      margin-top: 20px;

      @media (min-width: 1280px) {
        display: inline-block;
        margin-top: 0;
      }
    }

    .item {
      padding: 13px 24px;
      border-bottom: 2px solid #f1f3f6;

      p {
        font-family: 'FreightSansProMedium';
        color: #264e72;
        font-size: 20px;

        @media (min-width: 1280px) {
          flex: 0 0 70%;
        }
      }
    }
  }
}