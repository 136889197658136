#s4-bodyContainer {

  .section,
  div.section {
    padding: 60px 30px;

    @media (min-width: 768px) {
      padding: 70px 35px;
    }

    @media (min-width: 1280px) {
      padding: 100px 35px;
    }
  }

  .generic-button,
  a.generic-button,
  buttton.generic-button {
    display: inline-block;
    color: #fff;
    padding: 9px 30px;
    font-size: 23px;
    display: inline-block;
    background-color: #00aef0;
    border-radius: 10px;
    font-family: 'FreightSansProMedium';
    font-weight: 500;
    margin-top: 15px;
    transition: ease-out 0.3s all;
    text-align: center;
    min-width: 200px;

    &:hover {
      background-color: #1085b9;
      color: #fff;
      text-decoration: none;
    }

    @media (min-width: 1280px) {
      font-size: 22px;
    }

    &--transparent {
      border: 1px solid #00aef0;
      color: #00aef0;
      background-color: transparent;

      &:hover {
        background-color: #00aef0;
        color: #fff;
        border: 1px solid transparent;
      }
    }
  }

  /* titles and text */
  .generic-title {
    font-family: FreightSansProBold;
    font-size: 21px;
    font-weight: 700;
    color: #00aef0;
    line-height: 30px;
    margin-bottom: 18px;

    span {
      @apply text-oscura;
      display: block;
      font-family: 'FreightSansProMedium';
      font-size: 17px;

      @media (min-width: 768px) {
        font-size: 20px;
        font-weight: 500;
      }

      @media (min-width: 1280px) {
        font-size: 28px;
      }
    }

    @media (min-width: 768px) {
      font-size: 29px;
      line-height: 34px;
    }

    @media (min-width: 1280px) {
      font-size: 40px;
      line-height: 49px;
    }
  }

  .generic-text {
    font-family: 'FreightSansProMedium';
    font-size: 15px;
    font-weight: 500;
    color: #264e72;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    @media (min-width: 1280px) {
      font-size: 20px;
    }
  }

  .form-control {
    position: relative;

    .generic-input {
      width: 100%;
      border-radius: 12px;
      height: 60px;
      padding-left: 90px;
      font-family: 'FreightSansProMedium';
      font-size: 22px;
      color: #424242;
      letter-spacing: 0.9px;

      &:focus {
        outline: none;
      }
    }

    &__icon {
      color: #264e72;
      position: absolute;
      top: 10px;
      left: 33px;
      font-size: 36px;
    }

    .global-input {
      border-radius: 12px;
      background-color: #f8f9fb;
      border: 1px solid #cdd4e0;
      width: 100%;
      font-family: 'FreightSansProMedium';
      font-size: 22px;
      padding: 16px 12px;
      color: #264e72b3;

      &::placeholder {
        color: rgba(38, 78, 114, 0.7);
      }

      @media (min-width: 1280px) {
        padding: 16px 12px;
      }
    }

    .global-textarea {
      border-radius: 12px;
      background-color: #f8f9fb;
      border: 1px solid #cdd4e0;
      width: 100%;
      font-family: 'FreightSansProMedium';
      font-size: 22px;
      padding: 10px 12px;
      color: rgba(38, 78, 114, 0.7);
      resize: none;

      &::placeholder {
        color: rgba(38, 78, 114, 0.7);
      }
    }

    .global-select {
      border-radius: 12px;
      background-color: #f8f9fb;
      border: 1px solid #cdd4e0;
      width: 100%;
      font-family: 'FreightSansProMedium';
      font-size: 22px;
      padding: 10px 12px;
      color: rgba(38, 78, 114, 0.7);
      background-image: url(../../assets/img/chevron-down.svg);
      background-size: 30px 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-position: 95% 14px;

      @media (min-width: 1280px) {
        padding: 16px 12px;
        background-position: 99% 19px;
      }
    }
  }

  .cta-button {
    background-color: #00aef0;
    font-size: 23px;
    font-family: 'FreightSansProMedium';
    color: #fff;
    text-align: center;
    display: block;
    max-width: 261px;
    margin: 0 auto;
    border-radius: 15px;
    padding: 10px;
    letter-spacing: 0.6px;
  }

  .form-clear {
    background-color: #959595;
    width: 36px;
    height: 36px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 36px;
    top: 5px;

    span {
      color: #fff;
      font-size: 28px;
      font-weight: bold;
      position: static;
    }
  }
}

.media__img__icon [class^='icon-'] {
  background-position: 28px 23px !important;
}