.custom-list {
  &.check-list {
    & > li {
      @apply relative pl-8 text-oscura;
      font-size: 21px;

      &:before {
        @apply absolute text-clara mr-2 font-bold;
        left: 0;
        content: "\e83f";
        font-family: "feather" !important;
        font-size: 1.75rem;

        ul,
        ol {
          @apply ml-6;
          list-style-type: auto;
        }
      }

      &:not(:last-child) {
        @apply mb-3;
      }
    }
  }

  &.number-list {
    & > li {
      @apply relative text-oscura;
      font-family: "FreightSansProMedium";
      font-size: 22px;
      display: flex;
      align-items: center;
      padding-left: 66px;
      margin-bottom: 44px;
      counter-increment: list;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        @apply absolute bg-clara text-blanco text-center;
        top: -10px;
        left: 0;
        width: 50px;
        height: 50px;
        font-family: "Arial";
        border-radius: 50%;
        font-size: 28px;
        padding-top: 10px;
        content: counter(list);
      }

      ul,
      ol {
        @apply ml-6;
        list-style-type: auto;
      }
    }
  }

  &.bullet-list {
    & > li {
      @apply relative text-oscura pl-4 py-2;
      font-family: "FreightSansProMedium";
      font-size: 1rem;

      &:before {
        @apply absolute mr-2 text-clara;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "\2022";
        font-size: 1.5rem;
      }

      ul,
      ol {
        @apply ml-6;
        list-style-type: auto;
      }
    }
  }

  &.ninguno {
    & > li {
      font-family: "FreightSansProMedium";
      font-size: 1rem;

      ul,
      ol {
        @apply ml-6;
        list-style-type: auto;
      }
    }
  }
}
