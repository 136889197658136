.educativa-fideicomiso {
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .intro-container {
    @apply flex;

    @media (max-width: 1279px) {
      @apply flex-wrap;
    }

    .text-container {
      flex: 1 1 100%;
      @media (min-width: 1280px) {
        @apply order-first pr-14;
      }

      .info-text {
        .img-container {
          @media (min-width: 1280px) {
            flex: 1 1 275px;
          }
        }
      }
    }
    .list-container {
      @apply w-full;
      flex: 1 1 100%;

      @media (min-width: 1280px) {
        @apply order-last;
        flex: 1 0 500px;
      }

      .general-benefits {
        @apply bg-gris p-10;
        border-radius: 14px;

        h3 {
          @apply mb-4 text-oscurisima font-bold;
          font-size: 20px;
        }
      }
    }
  }

  .bg-oscura {
    .text-blanco {
      color: #fff;
    }
  }

  .participants {
    @apply grid grid-cols-1 gap-32 mt-6;

    @media (max-width: 768px) {
      @apply px-1;
    }

    @media (min-width: 769px) {
      @apply grid-cols-2;
    }

    @media (min-width: 1024px) and (max-width: 1072px) {
      @apply px-24;
    }

    @media (width: 1280px) {
      @apply px-24;
    }

    @media (min-width: 1280px) {
      @apply grid-cols-3;
    }
  }

  .types {
    @apply grid grid-cols-1 gap-8;

    @media (min-width: 768px) {
      @apply grid-cols-2;
    }

    @media (min-width: 1280px) {
      @apply grid-cols-3;
    }

    .hidden-type {
      -webkit-animation: fadein 0.5s ease-in;
      -moz-animation: fadein 0.5s ease-in;
      animation: fadein 0.5s ease-in;
    }
  }

  .link-container {
    @apply mt-16 text-center;

    .primary-button {
      @apply block bg-clara text-blanco py-4 mx-auto w-full;
      max-width: 300px;
      border-radius: 14px;
      font-size: 20px;

      &:hover {
        @apply bg-clara text-blanco;
      }
    }
  }
}
