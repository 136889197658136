.side-menu {
  background-color: #fff;
  box-shadow: 0px 0px 46px #00000017;
  padding: 30px 40px;
  border-radius: 30px;

  &__title {
    color: #264e72;
    font-size: 23px;
    font-family: FreightSansProBold;
  }

  &__trigger {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  /* &__bottom {
        margin-top: 20px;
    } */

  &__bottom-list {
    a {
      
      font-family: "FreightSansProMedium";
      font-size: 18px;
      font-weight: normal;
      color: #00aef0 !important;

      span{
        color: #00aef0 !important;
      }

      &.active {
        font-size: 22px;
        font-family: "FreightSansProMedium";
        color: #264e72;
        padding-bottom: 6px;
      }
    }

    .sub-acordeon {
      .trigger-sub-accordeon {
        color: #0c95c9;
        font-family: "FreightSansProMedium";
        font-size: 20px;
        cursor: pointer;
        font-weight: bold;
        display: flex;
        justify-content: space-between;

        &.active {
          font-size: 22px;
          font-family: "FreightSansProMedium";
          color: #264e72;
          padding-bottom: 6px;
        }
      }

      .feather {
        font-size: 28px;
        color: #0c95c9 !important;
      }
    }

    .sub-menu {
      padding-left: 16px;
    }

    li {
      border-bottom: 1px solid #cecece;
      padding: 12px 0;

      &:last-child {
        border: none;
      }
    }
  }
}
