#equipo-ejecutivo {
  .exec-info {
    @apply flex flex-wrap gap-8 mt-10;

    @media (min-width: 768px) {
      @apply flex-nowrap;
    }

    &__img-container {
      flex: 0 0 auto;
      max-width: 70%;

      img {
        @apply mx-auto;
      }

      a {
        @apply flex items-center gap-3 mt-6 flex-nowrap text-clara;
        font-size: 14px;

        @media (min-width: 640px) {
          font-size: 18px;
        }

        i {
          &:before {
            font-size: 24px;
          }
        }
      }
    }

    &__history {
      flex: 1 1 auto;

      p {
        font-family: "FreightSansProMedium";
        line-height: 1.4;
      }
    }
  }

  .article-text__help {
    &-icon {
      @media (max-width: 640px) {
        @apply text-center;
      }
    }

    &-text {
      h4 {
        @apply text-oscura font-bold;
      }

      a {
        @apply text-clara;
        font-size: 21px;
      }
    }
  }
}
