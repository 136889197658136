#Portada p,
#Portada h1 {
    margin-bottom: 15px !important;
}

#Portada h1 {

    color: #164E75 !important;
    font-family: "FreightSansProBold" !important;
    font-size: 22px !important;
    text-align: center !important;

    @media (min-width: 768px) {
        text-align: left !important;
        font-size: 30px !important;
    }
}

#Portada .article-text__head-title {
    color: #00aef0 !important;
    font-family: "FreightSansProBold" !important;
    font-size: 24px !important;
    text-align: center !important;
    margin-bottom: 15px !important;

    @media (min-width: 768px) {
        text-align: left !important;
        font-size: 36px !important;
    }

    &--no-margin {
        margin-bottom: 0 !important;
    }
}

#Portada .content ul {
    padding-left: 24px;
    position: relative;
}

#Portada .content ul li {
    font-family: "FreightSansProMedium";
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    color: #264e72;
}

.filter-tooggle {
    width: 32px;
}

#Portada .content ul li::before {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    --tw-bg-opacity: 1;
    background-color: rgba(0, 174, 240, var(--tw-bg-opacity));
    border-radius: 50%;
    left: 0;
    margin-top: 8px;
}

.document-block-title {
    h4 {
        margin-bottom: 20px;
        font-family: "FreightSansProSemiBold";
        font-size: 21px;
        text-align: center;

        @media (min-width: 768px) {
            font-family: "FreightSansProBold";
            font-size: 34px;
            text-align: left;
        }
    }
}