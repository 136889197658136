#s4-bodyContainer {
  .record {
    @media (min-width: 768px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    &__separator {
      span {
        font-size: 60px;
        color: #264e72;
        opacity: 0.12;
      }

      img {
        width: 25px;
      }
    }

    &__box {
      background-color: #fff;
      box-shadow: 0px 0px 46px #264e7229;
      border-radius: 29px;
      padding: 46px;

      @media (min-width: 768px) {
        display: flex;
      }

      @media (min-width: 1280px) {
        padding: 46px 92px;
        margin: 0 auto;
        width: 80%;
      }
    }

    &__info {
      text-align: center;
    }

    &__plus {
      color: #00aef0;
      font-size: 36px;
      font-weight: bold !important;
    }

    &__number {
      font-family: FreightSansProBold;
      color: #264e72;
      font-size: 40px;

      @media (min-width: 768px) {
        font-size: 60px;
      }
    }

    &__detail {
      font-family: "FreightSansProMedium";
      color: #264e72;
      font-size: 17px;
      line-height: 18px;
      margin-bottom: 36px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }

    .generic-button {
      margin-top: 0;

      @media (min-width: 1280px) {
        margin-bottom: 0;
        padding: 9px 60px;
      }
    }
  }

  .generic-title {
    &--record {
      color: #fff;
      text-align: center;

      @media (min-width: 1280px) {
        font-size: 48px;
        line-height: 44px;
      }

      span {
        display: block;
        color: #fff;
        font-size: 17px;

        @media (min-width: 768px) {
          font-size: 20px;
          font-weight: 500;
        }

        @media (min-width: 1280px) {
          font-size: 33px;
          line-height: 44px;
        }
      }
    }
  }
}
