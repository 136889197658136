.banner-top {
  @apply relative;
  min-height: 245px;
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* background-image: url(../../assets/img/banner/banner-bg.jpg); */
  background-position: 65% 0;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    background-position: left;
  }

  @media (min-width: 1200px) {
    min-height: 300px;
  }

  &__background {
    @apply absolute object-cover;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    object-position: right;

    @media (max-width: 767px) {
      object-position: left;
    }
  }

  .container {
    z-index: 2;
  }

  &__breadcrumbs {
    display: flex;
  }

  &__title {
    font-size: 21px;
    font-family: FreightSansProBold;
    color: #264e72;

    @media (max-width: 767px) {
      @apply text-center;
    }

    @media (min-width: 768px) {
      font-size: 24px;
      margin-bottom: -10px;
      max-width: 70%;
    }

    @media (min-width: 1200px) {
      font-size: 40px;
      margin-bottom: -10px;
      max-width: 60%;
    }
  }

  &__breadcrumbs {
    li {
      position: relative;
      display: flex;
      align-items: center;

      &:after {
        content: "\e829";
        font-family: "feather";
        font-size: 22px;
        margin: 0 10px;
        color: #484848;
        opacity: 0.5;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    a {
      font-size: 19;
      font-family: "FreightSansProMedium";
      color: #00aef0;

      &.active {
        color: #484848;
      }

      @media (min-width: 1200px) {
        font-size: 23px;
        font-family: "FreightSansProMedium";
        color: #00aef0;
      }
    }
  }
}
