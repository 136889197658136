.transparency-searchbar {
    background-color: #fff;
    box-shadow: 0 0 46px rgba(0, 0, 0, .09019607843137255);
    padding: 24px;
    border-radius: 12px;

    input {
        margin-right: 0 !important;
        background-image: url(../../assets/img/search.svg) !important;
    }

    select {
        border: 2px solid hsla(0, 0%, 91.8%, .8901960784313725) !important;

        @media (min-width: 1200px) {
            margin-bottom: 0 !important;
        }
    }
}

.document {
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }

    &__title {
        padding: 12px 24px;
        background-color: #264E72;
        border-radius: 12px 12px 0 0;

        h3 {
            color: #fff;
            font-family: "FreightSansProBold" !important;
            font-size: 20px;

            @media (min-width:1200px) {
                display: inline-block !important;
            }
        }

        span {
            color: #fff;
            font-family: "FreightSansProBook" !important;
            display: inline-block;
            font-size: 20px;

            @media (min-width:1200px) {
                display: inline-block !important;
            }
        }
    }

    &__info {
        padding: 24px;
        border-radius: 0 0 12px 12px;
        background-color: #F1F3F6;


    }

    &__desc {
        p {
            font-family: "FreightSansProBold" !important;
        }
    }

    &__spec {
        h4 {
            font-size: 18px !important;
            font-family: "FreightSansProMedium" !important;
            line-height: normal !important;
            color: #264E72 !important;

        }

        .modified {
            margin-top: 15px;

            @media (min-width:768px) {
                margin-top: 0;
            }

            h4 {
                font-size: 15px !important;

                @media (min-width:768px) {
                    font-size: 18px !important;
                }

            }

        }
    }



    &__link {
        color: #fff !important;
        padding: 13px 24px;
        font-size: 19px;
        background-color: #00aef0;
        border-radius: 10px;
        font-family: "FreightSansProSemiBold" !important;
        font-weight: 500;
        transition: all .3s ease-out;
        min-width: 200px;
        text-align: center;
    }
}

.document-list {
    &__title {
        background-color: #264E72;
        padding: 12px 14px;

        h3 {
            font-family: "FreightSansProSemiBold" !important;
            font-size: 20px;
            color: #fff;

        }
    }

    &__item {
        border-bottom: 2px solid #F1F3F6;
        padding: 20px 0;

        @media (min-width:768px) {
            padding: 24px;
        }

        &:last-of-type {
            border: none;
        }

        h3 {
            font-family: "FreightSansProMedium" !important;
            font-size: 18px;
            color: #264E72;
        }

        a {
            color: #fff !important;
            padding: 13px 24px;
            font-size: 19px;
            background-color: #00aef0;
            border-radius: 10px;
            font-family: "FreightSansProSemiBold !important";
            font-weight: 500;
            transition: all .3s ease-out;
            min-width: 178px;
            text-align: center;

            span {
                font-size: 21px;
            }


        }
    }
}

.accordion-section {
    border-bottom: 2px solid #F1F3F6;


    &:last-of-type {
        border: none;
    }

    &__title {
        font-family: "FreightSansProSemiBold" !important;
        font-size: 21px !important;
        color: #264E72 !important;

        @media (min-width:1024px) {
            font-size: 28px !important;
        }
    }

    .feather.accordion-icon {
        color: #09B0EF !important;
        font-size: 32px !important;
    }

    .accodion__content {
        padding: 24px 0;
    }
}

.return-transparency {
    font-size: 20px;
    font-family: "FreightSansProMedium" !important;
    color: #00aef0 !important;
    align-items: center;
}

.pagination-costum {
    .paginationUL .pageItem .pageButton {
        color: #ffffff !important;
        background-color: #00AEF0 !important;
    }

    .paginationUL .pageItem .activeButton {
        background-color: #F1F3F6 !important;
        color: #00AEF0 !important;
    }

    .paginationUL .pageItem .pageButton {
        border-radius: 7px !important;
    }
}

.project__select.project__select--hidden {
    display: none;
}