.navigation {
  z-index: 999;
  width: 100%;
  top: 0;
  left: 0;
  position: sticky;

  &__topbar {
    background-color: #264e72;
    padding-right: 40px;
    padding-left: 40px;
  }

  &__address {
    &:before {
      padding-right: 5px;
    }

    @apply text-blanco;

    font-size: 14px;
    font-family: 'FreightSansProBook';
    margin-right: 12px;
    letter-spacing: 0.5px;
    text-align: left;
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: 768px) {
      margin: 0 12px;
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: unset !important;
    }

    @media (min-width: 1280px) {
      margin: 0 12px;
      font-size: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__icon {
    font-size: 18px;
  }

  &__contactbar {
    @apply text-blanco;

    position: relative;
  }

  &__linklist {
    li {
      padding-left: 12px;
      padding-right: 12px;

      &:last-of-type {
        padding-right: 0;
      }

      a {
        @apply text-blanco;

        font-size: 15px;
        font-family: 'FreightSansProBook';
        margin: 0 12px;
        letter-spacing: 0.5px;
        text-align: center;
        font-weight: normal;

        span {
          font-weight: bold;
          color: #00aef0;
          transition: all ease-out 0.3s;
          display: inline-block;
        }

        &:active,
        &:visited {
          @apply text-blanco;
        }

        &:hover {
          span {
            transform: rotate(90deg);
          }
        }

        @media (min-width: 768px) {
          text-align: left;
          font-size: 15px;
        }

        @media (min-width: 1280px) {
          font-size: 15px;
        }
      }
    }
  }

  &__icon {
    svg {
      width: 22px;
    }
  }

  &__bottombar {
    padding: 20px 40px;
    background-color: #fff;
    border-bottom: 1px solid #ededed;
    position: relative;
  }

  &__action-item {
    @apply text-oscura;
    margin-left: 18px;
    position: relative;

    .dropdown-menu {
      width: 402px;
      left: 50%;
      margin-left: -201px;
      display: none;
      min-width: 254px;
      top: 50px;

      ul {
        border-radius: 15px;
        box-shadow: 0px 0px 46px #00000017;
      }

      &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #ffffff transparent;
        top: -10px;
        left: 50%;
        margin-left: -5px;
      }

      &__link {
        color: #00aef0;
        font-family: 'FreightSansProMedium';
        font-size: 22px;
      }
    }

    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }

  &__action-link {
    color: #264e72 !important;
    font-family: 'FreightSansProMedium';
    font-weight: bold;
    font-size: 22px;
    transition: all ease-out 0.5s;
    padding-left: 10px;
    padding: 10px;
    letter-spacing: 0.3px;

    &--active {
      color: #00aef0;
    }

    &:active,
    &:visited {
      @apply text-oscura !important;
    }

    &:hover {
      color: #00aef0 !important;

      .dropdown-menu {
        display: block;
      }
    }

    &--highlight {
      background-color: #00aef0;
      color: #ffffff !important;
      border-radius: 20px;
      padding: 10px 25px;

      &:visited {
        color: #ffffff !important;
      }

      &:hover {
        color: #ffffff !important;
        background-color: #264e72;
      }
    }
  }

  &__search {
    @media (min-width: 768px) {
      margin-left: 24px;
    }

    span {
      font-size: 34px;
      color: #264e72;
      transition: all ease-out 0.5s;
      opacity: 0.73;

      &:hover {
        color: #00aef0;
      }
    }
  }

  &__logo {
    @media (min-width: 1280px) {
      width: 260px;
    }
  }
}

.adressmodal {
  width: 100%;
  min-height: 210px;
  position: absolute;
  background-color: #264e72;
  z-index: 999;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  border-radius: 20px;
  top: 60px;

  &.-translate-y-full {
    top: 0;
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #264e72 transparent;
    top: -10px;
    left: 50%;
    margin-left: -10px;
  }

  @media (min-width: 768px) {
    width: 500px;
    left: 50%;
    margin-left: -250px;
  }

  &__close {
    align-self: flex-end;
    opacity: 1;
    color: #424242;
    font-size: 36px;
    position: absolute;
    right: 15px;
    top: 30px;
    z-index: 9;
    border: none;
    outline: none;
    min-width: 70px !important;

    .feather {
      font-size: 50px;
      opacity: 0.3;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__title {
    text-align: center;
  }

  &__title {
    @apply text-blanco;
    font-family: FreightSansProBold;
    margin-bottom: 24px;
    font-size: 30px;
  }

  &__info {
    @apply text-blanco;
    display: flex;
    font-family: 'FreightSansProBook';
    font-size: 14px;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3);
    margin-bottom: 24px;
    padding-bottom: 12px;
    letter-spacing: 0.8px;

    @media (min-width: 1280px) {
      font-size: 20px;
    }

    &:last-of-type {
      border: none;
      margin-bottom: 0;
    }

    strong {
      font-family: FreightSansProBold;
    }

    .feather {
      color: #fff;
      margin-right: 12px;
    }

    &-text {
      color: #ffffff;
    }
  }

  .generic-input {
    margin-top: 0;
    border-radius: 0;
  }

  &--search {
    background-color: #fff;
    width: 300px !important;
    height: auyo;
    padding: 0;
    border-radius: 20px;
    z-index: 9999;
    position: absolute;
    left: 50%;
    margin-left: -150px;

    &:before {
      display: none;
    }

    /* position the top  edge of the element at the middle of the parent */

    /* position the left edge of the element at the middle of the parent */

    /* transform: translate(-50%, -50%);
        margin-left: -425px; */
    @media (min-width: 375px) {
      background-color: #fff;
      width: 90% !important;
      height: auto;
      /* position the top  edge of the element at the middle of the parent */
      left: 50%;
      margin-left: -45%;
    }

    @media (min-width: 768px) {
      background-color: #fff;
      width: 600px !important;
      height: auto;
      /* position the top  edge of the element at the middle of the parent */
      left: 50%;
      margin-left: -300px;
      justify-content: center;
    }

    @media (min-width: 1280px) {
      background-color: #fff;
      width: 850px !important;
      height: auto;
      padding: 0;
      border-radius: 20px;
      z-index: 9999;
      position: absolute;
      top: 50% !important;
      /* position the top  edge of the element at the middle of the parent */
      left: 50%;
      /* position the left edge of the element at the middle of the parent */

      ////transform: translate(-50%, -50%);
      margin-left: -425px;

      /* position the top  edge of the element at the middle of the parent */

      /* position the left edge of the element at the middle of the parent */

      .generic-input {
        border-bottom: 2px solid rgb(195 195 195 / 50%) !important;
        border-top: transparent !important;
        border-left: transparent !important;
        border-right: transparent !important;
        border-radius: 0 !important;
      }

      .adressmodal__close {
        position: absolute;
        top: -60px;
        z-index: 1;

        span {
          @apply text-blanco;
          font-family: 'FreightSansProMedium';
          font-size: 24px;
        }
      }
    }
  }

  &--member {
    background-color: #fff;
    width: 300px !important;
    height: auyo;
    padding: 0;
    border-radius: 20px;
    z-index: 9999;
    position: fixed;
    left: 50%;
    margin-left: -150px;

    &:before {
      display: none;
    }

    /* position the top  edge of the element at the middle of the parent */

    /* position the left edge of the element at the middle of the parent */

    /* transform: translate(-50%, -50%);
        margin-left: -425px; */
    @media (min-width: 375px) {
      background-color: #fff;
      width: 90% !important;
      height: auto;
      /* position the top  edge of the element at the middle of the parent */
      left: 50%;
      margin-left: -45%;
      top: 10% !important;
    }

    @media (min-width: 768px) {
      background-color: #fff;
      width: 600px !important;
      height: auto;
      /* position the top  edge of the element at the middle of the parent */
      left: 50%;
      margin-left: -300px;
      justify-content: center;
      top: 10% !important;
    }

    @media (min-width: 1280px) {
      background-color: #fff;
      width: 1200px !important;
      height: 750px;
      padding: 0;
      border-radius: 20px;
      z-index: 9999;
      position: fixed;
      top: 13% !important;
      /* position the top  edge of the element at the middle of the parent */
      left: 50%;
      /* position the left edge of the element at the middle of the parent */

      transform: translate(-50%, -50%);
      margin-left: -600px;

      /* position the top  edge of the element at the middle of the parent */

      /* position the left edge of the element at the middle of the parent */
    }
  }
}

.overlay-sidemenu {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(38, 78, 114, 0.8);
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay-search {
  z-index: 998;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(38, 78, 114, 0.8);
}

.overlay-member {
  z-index: 998;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(38, 78, 114, 0.8);
}

.sidemenu {
  width: 100%;
  position: fixed;
  background-color: #ffffff;
  z-index: 1001;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  @media (min-width: 768px) {
    width: 400px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__close {
    span {
      color: #264e72;
      font-size: 36px;
    }
  }

  &__middle {
    margin-top: 48px;
  }

  &__sublink span {
    font-size: 18px;
    font-family: 'FreightSansProMedium';
    color: #264e72;
  }

  &__link {
    border-bottom: 1px solid #c7c7c7;
    display: block;
    font-family: 'FreightSansProMedium';
    color: #264e72 !important;
    font-size: 24px;

    position: relative;
    padding: 19px 0;

    &--bottom {
      border: none;
    }

    &:after {
      content: '\e844';
      position: absolute;
      top: 24px;
      font-family: 'feather' !important;
      height: 24px;
      width: 24px;
      right: 0;
    }

    &.active {
      &::after {
        content: '\e842';
      }
    }

    &:visited,
    &:active,
    &:hover {
      @apply text-oscura;
    }
  }

  &__bottom {
    margin-top: 48px;
  }

  &__secondary-menu {
    margin-top: 80px;

    a {
      font-family: 'FreightSansProMedium';
      display: block;
      color: #264e72;
      font-size: 24px;
      padding: 10px 0;
    }
  }

  &__bold {
    @apply text-blanco;
    background-color: #00aef0;
    border-radius: 25px;
    padding: 10px 13px;
    font-size: 24px;
    font-family: 'FreightSansProMedium';
    display: block;
    text-align: center;
    max-width: 345px;
    margin: 0 auto;
  }
}

.search-mid {
  transition: all ease-out 0.5s;
  padding: 36px;
  height: auto;
  overflow-y: auto;

  >a {
    margin-bottom: 21px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.open {
    height: 400px;
    transition: all ease-out 0.5s;
  }

  &__result {
    background-color: #ededed;
    border-radius: 15px;
    padding: 12px 24px;
    margin-bottom: 15px;
  }

  &__text {
    color: #264e72;
    font-family: 'FreightSansProMedium';
    font-size: 18px;
    font-weight: bold;
  }

  &__parragraf {
    color: #424242;
    font-family: 'FreightSansProBook';
    font-size: 18px;
  }

  &__link {
    color: #00aef0;
    font-family: 'FreightSansProMedium';
    font-size: 18px;
  }

  &__icon {
    color: #00aef0;
    font-size: 25px;
  }
}

.search-bottom {
  padding: 12px 36px;
  justify-content: space-between;

  &__link-question {
    position: relative;

    @media (min-width: 768px) {
      font-size: 24px;
    }

    img {
      width: 40px;
      margin-left: auto;
      position: absolute;
      right: 0;
      bottom: -6px;

      @media (min-width: 1280px) {
        width: 70px;
      }
    }
  }

  &__question {
    color: #264e72;
    font-family: 'FreightSansProMedium';
    font-size: 18px;
    margin-bottom: 16px;
    position: relative;
  }

  &__list {
    color: #00aef0;
    font-family: 'FreightSansProMedium';
    font-size: 14px;
    padding-left: 12px;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    span {
      margin-right: 6px;
    }

    >li>a {
      color: #00aef0;
    }

    >li>a {
      color: #00aef0;
    }
  }
}

.activator-adress {
  cursor: pointer;
}

.container .container--no-padding {
  padding: 0;
}

#adressModal {
  .icon-map-pin:before {
    font-size: 20px;
  }

  .icon-phone:before {
    font-size: 20px;
  }

  .icon-mail:before {
    font-size: 20px;
  }
}

.menu-child-links {
  li {
    margin-bottom: 6px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.chevronless {
  &:after {
    display: none !important;
  }
}

.sidemenu__sublink {
  @media (min-width: 1200px) {
    color: #00AEF0 !important;
    font-family: 'FreightSansProMedium' !important;
    font-weight: 500;
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .adressmodal--member {
    bottom: 10% !important;
    overflow-y: scroll !important;
    overflow-x: hidden;
  }
}