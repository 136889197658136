.noticias {
  padding: 63px 40px;

  &--blue {
    background-color: #264e72;


    h3,
    p {
      color: #fff !important;
    }

    .noticias__parragraf {
      color: #ffffff;
      
      h3,
      p {
        color: #fff !important;
      }

      .noticias__social {
        a{
          color: #fff;
        }
        p {


          span {

            color: #fff !important;

          }
        }

        i {
          color: #fff !important;

        }
      }
    }

  }

  &__text {
    @media (min-width: 1280px) {
      padding-right: 75px;
    }

    &--no-padding {
      padding: 0;
      width: 100%;
    }
  }

  &__title {
    @apply text-clara;
    font-family: "FreightSansProBold";
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;

    &--open {
      @apply text-clara;
    }

    @media (min-width: 1024px) {
      font-size: 28px;
      line-height: 32px;
    }

    @media (min-width: 1280px) {
      line-height: normal;
      font-size: 33px;
    }
  }

  &__image {
    width: 100%;
    height: 350px;
    border-radius: 10px;
    margin-top: 24px !important;
    margin-bottom: 24px !important;

    @media (min-width: 1024px) {
      margin: 0;
      object-fit: cover;
      height: 450px;
    }

    /* &--open {
      height: 150px;

      @media (min-width: 1024px) {
        height: 300px;
      }
    } */
  }

  &__date {
    background-color: #f1f3f6;
    display: inline-block;
    border-radius: 7px;
    padding: 3px 25px;
    font-family: "FreightSansProMedium";
    color: #264e72 !important;
    margin-bottom: 24px;

    h4 {
      font-size: 22px;
      font-family: "FreightSansProMedium";
      color: #264e72 !important;
    }

    .feather {
      font-size: 23px;
      margin-right: 10px;
    }

    &.noticias__date--other {
      font-family: "FreightSansProMedium";
      color: yellow;
      @apply text-oscura;

      @media (min-width: 1280px) {
        line-height: normal;
        font-size: 33px;
      }
    }
  }

  &__parragraf {
    font-size: 22px;
    font-family: "FreightSansProMedium";
    line-height: 38px;
    color: #264e72;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
    &--open{
      display: unset;
      text-overflow: unset;
      line-clamp: unset;
      
    }
    h4 {
      font-size: 28px;
      font-family: "FreightSansProBold";
      line-height: 38px;
      color: #264e72;
      margin-bottom: 12px;
    }

    &--open {
      @media (min-width: 1024px) {
        padding-right: 40px;
      }
    }
  }

  &__other {
    margin-top: 90px;

    .splide__arrow--prev {
      left: -1em !important;
      font-size: 8px !important;
    }

    .splide__arrow--next {
      right: -1em !important;
      font-size: 8px !important;
    }

    .splide__arrow {
      opacity: 1 !important;
      background-color: transparent;
    }

    @apply text-clara;

    hr {
      border-top: 3px solid #ececec;
      border-top-width: 3px;
    }

    h4 {
      @apply text-clara;
      margin: 0;
      font-size: 22px;

      @media (min-width: 1280px) {
        font-size: 40px;
      }
    }

    a h4 {
      @apply text-clara;
      margin: 0;
      font-size: 22px;

      @media (min-width: 1280px) {
        font-size: 40px;
      }
    }

    .splide__slide {
      padding: 10px;

      @media (min-width: 1280px) {
        padding: 24px;
      }
    }

    a {
      @apply text-clara;
      font-size: 19px;
      font-family: "FreightSansProMedium";

      @media (min-width: 1024px) {
        font-size: 22px;
      }
    }

    .card {
      box-shadow: 0px 0px 46px #00000017;
      border-radius: 23px;
      background-color: #fff;
      padding: 34px;
      @apply text-oscurisima;
      margin-bottom: 24px;
      margin-top: 24px;
      margin-bottom: 24px;

      .noticias__date {
        margin-bottom: 0;
        font-size: 22px;
      }

      h4 {
        @apply text-clara;
        font-family: "FreightSansProBold";

        @media (min-width: 1280px) {
          font-size: 27px;
        }
      }

      .noticias__date h4 {
        font-family: "FreightSansProMedium";
        font-size: 15px;
        @apply text-oscura;

        @media (min-width: 1280px) {
          font-size: 20px;
        }
      }

      h3 {
        @apply text-clara;
        font-family: "FreightSansProBold";
        margin-bottom: 12px;

        @media (min-width: 1280px) {
          font-size: 27px;
        }
      }

      p {
        font-size: 21px !important;
        font-family: "FreightSansProMedium";
        @apply text-clara !important;
        line-height: 27px !important;
        margin-bottom: 12px !important;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;

        @media (min-width: 1280px) {
          padding-right: 36px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
  }

  &__social {
    p {
      align-items: center;
      display: flex;

      span {
        font-size: 18px !important;
        font-family: "FreightSansProMedium" !important;
        color: #00aef0 !important;

      }
    }

    i {
      color: #00aef0 !important;
      margin-right: 12px;
      font-size: 28px;
    }
  }
}

.social-share {
  &__box {
    @media (min-width: 1024px) {
      background-color: #ffffff;
      box-shadow: 0px 0px 46px #00000017;
      border-radius: 23px;
      padding: 29px 13px;
      width: 115px;
      margin-left: auto;
      text-align: center;

      a {
        margin-right: 6px;

        @media (min-width: 1024px) {
          margin: 0;
          margin-top: 6px;
          margin-bottom: 12px;
          display: inline-block;
        }
      }
    }

    img {
      width: 36px;
      display: inline-block;
      height: 36px;
      object-fit: cover;

      @media (min-width: 1024px) {
        display: inline-block;
        width: 48px;
        height: 48px;
      }
    }

    h4 {
      color: #737677;
      text-align: center;
      font-size: 19px;
      font-family: "FreightSansProMedium";
    }
  }
}