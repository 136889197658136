#gobierno-corporativo {
  .intro {
    .article-text__head-title {
      @media (max-width: 1024px) {
        font-size: 21px;
      }
    }

    .content {
      @apply text-oscura;
      font-size: 16px;
      line-height: 24px;

      @media (min-width: 1024px) {
        font-size: 21px;
        line-height: 38px;
      }
    }
  }
}
