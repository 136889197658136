#faq {
  .article-text__head-title--center {
    @media (max-width: 767px) {
      font-size: 21px;
    }
  }

  .toggle-list {
    &__item {
      &--faq {
        background-color: #f1f3f6;
        border-radius: 16px;
        border: none;

        &:not(:last-child) {
          @apply mb-5;
        }

        label {
          padding: 25px 32px;

          span {
            @apply text-oscura;
          }
        }

        .content {
          padding-left: 32px;
          padding-right: 32px;
          margin: 0;

          p {
            @apply text-oscura;
            font-family: "FreightSansProMedium";
            font-size: 22px;
          }
        }
      }

      input[type="checkbox"] {
        @apply hidden;

        &:checked {
          /* TODO: Remover en SharePoint, manejar este estado a través de la clase 'minus', se tiene aquí únicamente para fines de maquetación */
          + label {
            .arrow-icon {
              .icon-container {
                transform: rotate(90deg);
              }
            }
          }

          ~ .content {
            @apply pb-4;
            max-height: 100vh;
          }
        }
      }

      label {
        @apply flex items-center justify-between py-4 text-base font-bold text-clara;
        width: 100%;

        @media (min-width: 768px) {
          font-size: 1.15rem;
        }

        .arrow-icon {
          @apply p-1 bg-clara;
          border-radius: 4px;
          line-height: 1.5rem;

          .icon-container {
            @apply block;
            transition: transform 500ms ease-in-out;

            i {
              &:before {
                @apply text-blanco;
                font-size: 2rem;
              }
            }
          }
        }
      }

      .content {
        @apply text-oscura;
        max-height: 0px;
        overflow: hidden;

        transition: max-height 0.5s ease-in-out, margin-top 0.25s ease-in-out;

        @media (min-width: 768px) {
          @apply text-2xl;
        }

        p {
          @apply text-oscura;

          @media (min-width: 768px) {
            font-size: 20px;
          }
        }

        ul {
          list-style-type: disc;
          padding: revert;

          ul {
            list-style-type: circle;
          }
        }
      }
    }
  }
}
