/* this imports all of the component styles */
/* @import url(components/layout/layout.css);
@import url(components/nav/nav.css);
@import url(components/general/general.css);
@import url(components/slide/slide.css);
@import url(components/footer/footer.css);
@import url(components/offer/offer.css);
@import url(components/chat/chat.css);
@import url(components/welcome-section/welcome-section.css);
@import url(components/banner-top/banner-top.css);
@import url(components/banner-top/sub-bar.css);
@import url(components/sidemenu/sidemenu.css);
@import url(components/article/article.css);
@import url(../src/components/record-section/record-section.css);

@import url(components/equipo-ejecutivo/exec-info.css);
@import url(components/equipo-ejecutivo/board-members.css);

@import url(components/fideicomiso/que-es.css);
@import url(components/fideicomiso/multimedia.css);
@import url(components/fideicomiso/marco-legal.css);

@import url(components/history/history.css);


*/
@import url(styles/css/layout.css);
@import url(styles/css/styles.css);
@import url(components/header/header.css);
@import url(components/header/sidemenu.css);
@import url(components/header/slide.css);
@import url(components/header/welcome.css);
@import url(components/footer/footer.css);
@import url(components/records/records.css);
@import url(components/offer/offer.css);
/* this imports icon fonts and text-fonts */
@import url(styles/css/icon-fonts.css);

@import url(styles/css/sharepoint-fix.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "FreightSansProBold";

    src: url("styles/fonts/FreightSansProBold-Regular.woff2") format("woff2"),
      url("styles/fonts/FreightSansProBold-Regular.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "FreightSansProBook";
    src: url("styles/fonts/FreightSansProBook-Regular.woff2") format("woff2"),
      url("styles/fonts/FreightSansProBook-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "FreightSansProMedium";
    src: url("styles/fonts/FreightSansProMedium-Regular.woff2") format("woff2"),
      url("styles/fonts/FreightSansProMedium-Regular.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "feather" !important;
    src: url("styles/fonts/feather.eot?t=1525787366991");
    /* IE9*/
    src: url("styles/fonts/feather.eot?t=1525787366991#iefix") format("embedded-opentype"),
      /* IE6-IE8 */
      url("styles/fonts/feather.woff?t=1525787366991") format("woff"),
      /* chrome, firefox */
      url("styles/fonts/feather.ttf?t=1525787366991") format("truetype"),
      /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url("styles/fonts/feather.svg?t=1525787366991#feather") format("svg");
    /* iOS 4.1- */
  }

  @font-face {
    font-family: "FreightSansProSemiBold";

    src: url("styles/fonts/FreightSansProSemibold-Regular.woff2") format("woff2"),
      url("styles/fonts/FreightSansProSemibold-Regular.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }


  #root {
    max-width: 100vw;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 700px) {
      padding-left: 0px;
      padding-right: 0px;
    }

    @media (min-width: 1536px) {
      max-width: 1300px !important;
    }
  }
}

.container .container--no-padding {
  padding: 0;
}

p {
  /* font-size: 22px;
  font-family: "FreightSansProMedium";
  line-height: 38px;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  font-family: "FreightSansProMedium";
  font-size: 19px; */
  /* color: #264e72 !important; */
  /* margin-bottom: 16px; */
}

/* screens: {
    'sm': '640px',
    // => @media (min-width: 640px) { ... }

    'md': '768px',
    // => @media (min-width: 768px) { ... }

    'lg': '1024px',
    // => @media (min-width: 1024px) { ... }

    'xl': '1280px',
    // => @media (min-width: 1280px) { ... }

    '2xl': '1536px',
    // => @media (min-width: 1536px) { ... }
  } */

/* TODO: CSS TEMPORAL */
@media (min-width: 992px) {
  .container {
    max-width: 1140px;
  }

  .navigation__logo {
    width: 230px !important;
  }

  /* .navigation__logo {
        border:solid 1px red !important;
    } */

  #s4-bodyContainer .slider-home__title {
    line-height: 41px !important;
    font-size: 3.5em !important;
  }

  #s4-bodyContainer .ms-rtestate-field p {
    font-size: 1.1rem !important;
  }

  #s4-bodyContainer .welcome-section__text-block h2 {
    font-size: 38px !important;
    line-height: 42px !important;
  }

  #s4-bodyContainer .welcome-section__text-block p {
    font-size: 18px !important;
    line-height: 1.3em !important;
  }

  #s4-bodyContainer .generic-title--record {
    font-size: 38px !important;
    line-height: 38px !important;
  }

  #s4-bodyContainer .generic-title--record span {
    font-size: 24px !important;
    line-height: 38px !important;
  }

  #s4-bodyContainer .record__number {
    font-size: 44px !important;
  }

  #s4-bodyContainer .generic-title {
    font-size: 35px !important;
    line-height: 38px !important;
  }

  #s4-bodyContainer .generic-title span {
    font-size: 24px !important;
  }

  #s4-bodyContainer .ms-rtestate-field p {
    font-size: 16px !important;
  }

  #s4-bodyContainer .generic-button,
  #s4-bodyContainer a.generic-button,
  #s4-bodyContainer buttton.generic-button {
    font-size: 18px !important;
  }

  .offer__tab.active {
    font-size: 18px !important;
  }

  .offer__tab {
    font-size: 18px !important;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__text {
    font-size: 15px !important;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__text--phone {
    margin-top: 24px;
    font-size: 15px !important;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__link {
    font-size: 15px !important;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__disclaimer {
    font-size: 15px !important;
  }

  #s4-bodyContainer .ms-rtestate-field .footer__closing-links a,
  #s4-bodyContainer .ms-rtestate-field .footer__closing-links span {
    font-size: 15px !important;
  }

  .navigation__action-link {
    font-size: 17px !important;
  }

  .navigation__linklist li a {
    font-size: 16px !important;
  }

  .section.offer .md\:gap-6 {
    gap: 4.5rem;
  }

  #historia .content p,
  #s4-bodyContainer .ms-rtestate-field p,
  #quienes-somos .content p {
    font-size: 18px !important;
    line-height: 21px !important;
  }

  #s4-bodyContainer .article-text__head-title,
  #quienes-somos .content h1 {
    font-size: 25px !important;
    margin-bottom: 15px !important;
  }

  #quienes-somos .content ul li {
    font-size: 16px;
  }

  .side-menu__bottom-list a.active,
  .side-menu__bottom-list a {
    font-size: 18px !important;
  }

  #s4-bodyContainer .article-text__document-title {
    font-size: 20px !important;
  }

  .board-container__member .name {
    font-size: 18px !important;
  }

  #fideicomiso .description div,
  #fideicomiso .description li,
  #fideicomiso .description p,
  #fideicomiso .nota p,
  .custom-list.number-list>li {
    font-size: 16px !important;
  }

  .tabs-container .react-tabs__tab {
    font-size: 19px !important;
  }

  .project-list__name {
    font-size: 20px !important;
    line-height: 30px;
  }

  .banner-top__title {
    font-size: 40px !important;
  }

  #s4-bodyContainer .section,
  #s4-bodyContainer div.section {
    padding: 50px 35px;
  }

  .offer__tabs-list {
    max-height: 440px;
    overflow-y: scroll;
    height: 440px;
  }

  .adressmodal__info {
    font-size: 16px !important;
  }
}