.media {
  border-radius: 14px;
  box-shadow: 2px 2px 16px #1a43591a;

  &__info__title {
    color: #00aef0 !important;
  }

  &__img {
    @apply relative;

    img {
      border-radius: 14px;
    }

    .background {
      @apply absolute w-full bg-negro opacity-30;
      top: 0;
      bottom: 0;
      border-radius: 14px;
    }

    &__icon {
      @apply absolute p-2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      z-index: 2;

      padding: 15px;
      width: 140px;
      height: 140px;

      background-color: rgba(255, 255, 255, 0.4);

      [class^='icon-'],
      [class*=' icon-'] {
        @apply inline-block bg-clara p-3;
        border-radius: 100%;
        background-image: url(../../assets/img/play.svg);
        height: 80px;
        width: 90px;
        padding: 55px !important;
        background-position: center;
        background-repeat: no-repeat;

        &:before {
          @apply text-blanco;
          font-size: 48px;
        }
      }
    }
  }
}