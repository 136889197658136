.head-section {
  @apply flex justify-between items-center;

  @media (max-width: 1023px) {
    @apply gap-6 flex-wrap;
  }

  h3 {
    @media (max-width: 767px) {
      @apply mx-auto;
    }
  }

  form {
    @apply flex justify-between items-center gap-6;

    @media (max-width: 767px) {
      @apply flex-wrap;
    }

    @media (max-width: 1023px) {
      flex: 1 1 100%;
    }

    label {
      @apply font-bold text-oscura;
      font-size: 22px;

      @media (max-width: 639px) {
        flex: 1 1 100%;
      }
    }

    select {
      @apply px-4 py-2 bg-blanco text-clara font-bold;
      border-radius: 8px;
      border: 2px solid #264e723d;
      font-size: 21px;

      @media (max-width: 767px) {
        flex: 1 1 100%;
      }

      @media (max-width: 1023px) {
        flex: 1 1 auto;
      }

      option {
        @apply font-normal;
      }
    }
  }
}

.media-container {
  @apply grid grid-cols-1 gap-10 mt-6;

  @media (min-width: 768px) {
    @apply grid-cols-2;
  }

  @media (min-width: 1024px) {
    @apply grid-cols-3;
  }

  @media (min-width: 1200px) {
    @apply grid-cols-4;
  }

  .media {
    border-radius: 14px;
    box-shadow: 2px 2px 16px #1a43591a;
    &__img {
      @apply relative;

      img {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
      }

      .background {
        @apply absolute w-full bg-negro opacity-30;
        top: 0;
        bottom: 0;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
      }

      &__icon {
        @apply absolute p-2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        z-index: 2;

        background-color: rgba(255, 255, 255, 0.4);

        [class^="icon-"],
        [class*=" icon-"] {
          @apply inline-block bg-clara p-3;
          border-radius: 100%;

          &:before {
            @apply text-blanco;
            font-size: 48px;
          }
        }
      }
    }

    &__info {
      @apply p-4 pb-8;

      &__title {
        @apply text-clara font-semibold;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__details {
        @apply text-oscura;
      }
    }
  }
}
