#s4-bodyContainer {
  .slider-home {
    position: relative;
    width: 100%;

    .splide__pagination {
      @media (min-width: 540px) {
        bottom: 3.5em;
      }

      .splide__pagination__page {
        background-color: #bbc3cb;
        width: 8px;
        height: 8px;
        opacity: 1;
        transform: scale(1.4);
      }

      .splide__pagination__page.is-active {
        background-color: #00aef0;
        width: 8px;
        height: 8px;
      }
    }

    .splide__arrow {
      background-color: transparent;
      display: none;
      filter: drop-shadow(1px 1px 1px #f1f1f1);

      span {
        font-size: 48px;
        color: #707070;
      }

      @media (min-width: 1200px) {
        display: block;
      }
    }

    .slick-slide > div {
      &:first-of-type {
        position: relative;
        height: 100%;
        background-color: #fff;
      }
    }

    .slick-dots {
      @media (min-width: 1200px) {
        top: 0;
        bottom: 0;
        text-align: right;
        right: calc(5% + 48px);
        width: 32px;
        left: auto;
        justify-content: center;
        display: flex;
        flex-flow: column;
        height: auto;

        li {
          margin: 5px 0px;
          height: 20px;
          width: 20px;
          display: inline-block;

          button {
            height: 20px;
            width: 20px;
          }
        }
      }
    }

    .slick-dots li.slick-active button {
      background-color: #ffffff;
    }

    .slick-dots li button {
      border: 2px solid #fff;
    }

    .slick-dots li button {
      &:hover {
        background: #fff;
      }
    }

    .slick-prev,
    .slick-next {
      &:before {
        color: #ffffff;
      }
    }

    &__item {
      height: auto;
      padding: 36px 0;
      position: relative;
      display: inline-flex !important;
      align-items: center;
      min-height: 350px;
      width: 100%;

      @media (min-width: 768px) {
        text-align: left;
        min-height: 376px;
      }

      @media (min-width: 1280px) {
        text-align: left;
        min-height: 600px;
      }

      .full-link {
        @apply h-full flex items-center;
      }
    }

    .container {
      @apply px-0;
      position: relative;
      z-index: 11;
    }

    &__image {
      width: 100%;
      position: absolute;
      height: 100%;
      object-fit: cover;
      object-position: left;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 9;
    }

    &__video {
      width: 100%;
      position: absolute;
      height: 100%;
      object-fit: cover;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 9;
    }

    &__overlay {
      width: 100%;
      position: absolute;
      height: 100%;
      background-color: rgba($color: #264e72, $alpha: 0.3);
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 10;

      &--darker {
        background-color: rgba($color: #264e72, $alpha: 0.7);
      }
    }

    &__subtitle {
      text-align: center;
      color: #ffffff;
      font-size: 16px;
      margin: 0;
      letter-spacing: 0.83px;
      text-transform: uppercase;

      @media (min-width: 992px) {
        font-size: 30px;
        text-align: left;
      }
    }

    &__title {
      text-align: center;
      margin: 6px 0;
      color: #264e72 !important;
      font-size: 21px;
      line-height: 25px;
      font-weight: bold;
      font-family: FreightSansProBold;
      font-weight: 700;

      @media (min-width: 768px) {
        text-align: left;
        font-size: 28px;
        line-height: 30px;
      }

      @media (min-width: 1280px) {
        font-size: 55px;
        line-height: 58px;
      }
    }

    &__text {
      text-align: center;
      margin: 0;
      color: #264e72;
      font-size: 15px;
      font-family: 'FreightSansProMedium';
      font-weight: 500;

      @media (min-width: 768px) {
        text-align: left;
      }

      @media (min-width: 1280px) {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 24px;
      }

      &--corporate {
        display: none;

        @media (min-width: 992px) {
          display: block;
        }
      }
    }

    &__anchor-block {
      width: 100%;
      text-align: center;

      @media (min-width: 768px) {
        text-align: left;
      }
    }

    &__link {
      color: #fff;
      padding: 9px 45px;
      font-size: 15px;
      display: inline-block;
      background-color: #00aef0;
      border-radius: 10px;
      font-family: 'FreightSansProMedium';
      font-weight: 500;
      margin-top: 15px;
      transition: ease-out 0.3s all;
      min-width: 200px;
      text-align: center;

      &:visited,
      &:active,
      &:focus {
        color: #fff;
      }

      &:hover {
        background-color: #1085b9;
        color: #fff;
        text-decoration: none;
      }

      @media (min-width: 1280px) {
        font-size: 20px;
        padding: 13px 0;
        min-width: 308px;
      }
    }

    .splide__arrow--prev {
      @media (min-width: 1536px) {
        left: 3rem;
      }
    }

    .splide__arrow--next {
      @media (min-width: 1536px) {
        right: 3rem;
      }
    }
  }
}
