.horizontal-media-card {
  @apply flex items-center;
  margin-top: 24px;

  &-box {
    position: relative;
    margin-right: 4%;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 9;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        width: 100px;
        height: 100px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        [class^="icon-"],
        [class*=" icon-"] {
          height: auto;
          margin: 0;

          &:before {
            @apply text-clara;
            font-size: 48px;
          }
        }
      }
    }

    img {
      width: 385px;
      height: 227px;
      object-fit: cover;
    }
  }

  &-text {
    width: 48%;
  }

  &-paragraph {
    font-family: "FreightSansProMedium";
    font-size: 20px;
  }
}
